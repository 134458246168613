import { BaseError } from './base.js';
export class ChainNotConfiguredError extends BaseError {
  constructor() {
    super('Chain not configured.');
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'ChainNotConfiguredError'
    });
  }
}
export class ConnectorAlreadyConnectedError extends BaseError {
  constructor() {
    super('Connector already connected.');
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'ConnectorAlreadyConnectedError'
    });
  }
}
export class ConnectorNotConnectedError extends BaseError {
  constructor() {
    super('Connector not connected.');
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'ConnectorNotConnectedError'
    });
  }
}
export class ConnectorNotFoundError extends BaseError {
  constructor() {
    super('Connector not found.');
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'ConnectorNotFoundError'
    });
  }
}
export class ConnectorAccountNotFoundError extends BaseError {
  constructor({
    address,
    connector
  }) {
    super(`Account "${address}" not found for connector "${connector.name}".`);
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'ConnectorAccountNotFoundError'
    });
  }
}
export class ConnectorChainMismatchError extends BaseError {
  constructor({
    connectionChainId,
    connectorChainId
  }) {
    super(`The current chain of the connector (id: ${connectorChainId}) does not match the connection's chain (id: ${connectionChainId}).`, {
      metaMessages: [`Current Chain ID:  ${connectorChainId}`, `Expected Chain ID: ${connectionChainId}`]
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'ConnectorChainMismatchError'
    });
  }
}
