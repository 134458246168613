<div class="game-container" *ngIf="showGame" (click)="$event.stopImmediatePropagation()">

    <app-wheel (close)="closeModal($event)"
               (refresh)="triggerRefresh(1)"
               [prizes]="gameData?.wheelGame?.prizes || []"
               *ngIf="selectedGame == 1"></app-wheel>

    <app-cards (close)="closeModal($event)"
               (refresh)="triggerRefresh(2)"
               [capsuleNumber]="gameData?.capsuleGame?.capsuleNumber || 0"
               *ngIf="selectedGame == 2"></app-cards>

    <app-spaceship (close)="closeModal($event)"
                   (refresh)="triggerRefresh(3)"
                   [maxInput]="gameData?.journeyGame?.maxInput || 0"
                   *ngIf="selectedGame == 3"></app-spaceship>

    <app-check-in (close)="closeModal($event)"
                  (refresh)="triggerRefresh(4)"
                  [shardsNumber]="gameData?.shardGame?.shardsNumber || 0"
                  *ngIf="selectedGame == 4"></app-check-in>
    <app-shop (close)="closeModal($event)"
                  (refresh)="refreshData.emit()"
                  *ngIf="selectedGame == 5"></app-shop>
</div>


<div class="actions-container">
    <button (click)="openWheelModal()" [class.disabled]="!wheelEnabled">
        <span *ngIf="wheelCooldown" class="cooldown">{{wheelCooldown}}</span>
        <ng-container *ngIf="wheelEnabled">
            <img src="/assets/games/notification.png" height="30" class="notification">
            <img src="/assets/games/button-wheel.gif" height="77">
        </ng-container>
        <img src="/assets/games/buttonwheel.png" height="77" *ngIf="!wheelEnabled">
    </button>

    <button (click)="openCardModal()" [class.disabled]="!capsuleEnabled">
        <span *ngIf="capsuleCooldown" class="cooldown">{{capsuleCooldown}}</span>
        <ng-container *ngIf="capsuleEnabled">
            <img src="/assets/games/notification.png" height="30" class="notification">
            <img src="/assets/games/button-capsule.gif" height="77">
        </ng-container>
        <img src="/assets/games/buttoncapsule.png" height="77" *ngIf="!capsuleEnabled">
    </button>

    <button (click)="openSpaceshipModal()" [class.disabled]="!spaceshipEnabled">
        <span *ngIf="spaceshipCooldown" class="cooldown">{{spaceshipCooldown}}</span>
        <ng-container *ngIf="spaceshipEnabled">
            <img src="/assets/games/notification.png" height="30" class="notification">
            <img src="/assets/games/button-spaceship.gif" height="77">
        </ng-container>
        <img src="/assets/games/buttonspaceship.png" height="77" *ngIf="!spaceshipEnabled">
    </button>

    <button (click)="openCheckinModal()" [class.disabled]="!shardsEnabled">
        <span *ngIf="shardCooldown" class="cooldown">{{shardCooldown}}</span>
        <ng-container *ngIf="shardsEnabled">
            <img src="/assets/games/notification.png" height="30" class="notification">
            <img src="/assets/games/button-shard.gif" height="77">
        </ng-container>
        <img src="/assets/games/buttonshard.png" height="77" *ngIf="!shardsEnabled">
    </button>
    <button (click)="openShopModal()">
        <img src="/assets/games/notification.png" height="30" class="notification">
        <img src="/assets/games/button-shop.gif" height="77">
    </button>
</div>
