import Phaser from "phaser";

declare const WebFont: any;
export class InputModal extends Phaser.GameObjects.Container {

    public modal: Phaser.GameObjects.Image;

    private readonly closeButton: Phaser.GameObjects.Image;

    private readonly thankYouTitle: Phaser.GameObjects.Text;
    private readonly thankYouDescription: Phaser.GameObjects.Text;
    private readonly discordButton: Phaser.GameObjects.Image;

    get isMobile(): boolean {
        const screenRatio = innerWidth / innerHeight
        return screenRatio < 0.70;
    }

    preload(): void {
        WebFont.load({
            custom: {
                families: ['Jersey20']
            }});

    }

    constructor(scene: Phaser.Scene, x: number, y: number, callbackComplete: () => void) {
        super(scene, x, y);

        this.modal =  scene.add.image(window.innerWidth / 2, window.innerHeight / 2, 'modal');
        this.modal.setDisplaySize(this.isMobile ? 300: 540, 520);

        this.closeButton =  scene.add.image(this.modal.x + (this.modal.width /2) - (this.isMobile ? 100 : 50), this.modal.y - (this.modal.height /2) + 20, 'close-button').setInteractive({cursor: 'pointer'});

        this.thankYouTitle = scene.add.text(0, 0, 'THANK YOU!', {
            fontFamily: 'Jersey20',
            fontSize: 32,
            color: "#0E1637",
            align: 'center',
        });
        Phaser.Display.Align.In.TopCenter(this.thankYouTitle, this.modal, 0 , -120);

        this.thankYouDescription = scene.add.text(0, 0, 'Don’t forget to join Taco Studios on Discord for all the latest updates!', {
            fontFamily: 'Jersey20',
            fontSize: 24,
            color: "#293A7B",
            align: 'center',
            wordWrap: {
                width: 280
            }
        });
        Phaser.Display.Align.In.TopCenter(this.thankYouDescription, this.modal, 0 , -160);
        this.discordButton =  scene.add.image(0, 0, 'button-link-discord').setInteractive({cursor: 'pointer'});
        Phaser.Display.Align.In.TopCenter(this.discordButton, this.modal, 0, -280);


        this.add([this.modal, this.closeButton, this.thankYouTitle, this.thankYouDescription, this.discordButton]);
        scene.add.existing(this);
        this.setOpenLink(this.discordButton, 'https://discord.gg/tacocrypto');
        this.closeButton.on('pointerup', (e: any) =>  callbackComplete());
    }

    private setOpenLink(image: Phaser.GameObjects.Image, url: string) {
        image.setInteractive({cursor: 'pointer'});
        image.on('pointerup', () => {
            window.open(url, "_blank");
        }, this);
    }

}
