<div class="container-modal" (click)="$event.stopImmediatePropagation(); close()">
    <div class="modal modal-open flex flex-col items-center" (click)="$event.stopImmediatePropagation()">
        <div class="stroke top"></div>
        <div class="stroke left"></div>
        <div class="stroke right"></div>
        <div class="stroke bottom"></div>

        <div class="close pointer" (click)="close()">
            <img src="/assets/images/button-close.png" class="full-size">
        </div>

        <span class="description centerText">
            A new journey awaits.<br>Are you ready for the next chapter?
        </span>

        <div id="mlb2-16482473" class="ml-form-embedContainer ml-subscribe-form ml-subscribe-form-16482473">
            <div class="ml-form-align-center ">
                <div class="ml-form-embedWrapper embedForm">
                    <div class="ml-form-embedBody ml-form-embedBodyDefault row-form">
                        <div class="ml-form-embedContent" style="margin-bottom: 0px; ">
                        </div>
                        <form class="ml-block-form"
                              action="https://assets.mailerlite.com/jsonp/1027052/forms/127092641772865229/subscribe"
                              data-code="" method="post" target="_blank">
                            <div class="ml-form-formContent">

                                <div class="ml-form-fieldRow ml-last-item">
                                    <div class="ml-field-group ml-field-email ml-validate-email ml-validate-required">
                                        <input aria-label="email" aria-required="true" type="email" class="form-control"
                                               data-inputmask="" name="fields[email]" placeholder="Email"
                                               autocomplete="email">
                                    </div>
                                </div>
                            </div>
                            <input type="hidden" name="ml-submit" value="1">

                            <div class="ml-form-embedSubmit">

                                <button type="submit" class="primary">Subscribe</button>

                                <button disabled="disabled" style="display: none;" type="button" class="loading">
                                    <div class="ml-form-embedSubmitLoad"></div>
                                    <span class="sr-only">Loading...</span>
                                </button>
                            </div>


                            <input type="hidden" name="anticsrf" value="true">
                        </form>
                    </div>
                    <div class="ml-form-successBody row-success" style="display: none">
                        <div class="ml-form-successContent">
                            <h4>Thank you!</h4>
                            <p>You have successfully joined our subscriber list.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="container-input"></div>
        <div class="button-confirm pointer"></div>-->
    </div>
</div>

