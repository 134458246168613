import {Injectable} from "@angular/core";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";


@Injectable({providedIn: 'root'})
export class RefCodeService{

    private paramsSubscription: Subscription | undefined;
    refCode: string | null | undefined;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
    ) {
        this.paramsSubscription?.unsubscribe()
        this.paramsSubscription = this._activatedRoute.queryParams.subscribe(params => {
            this.refCode = params['ref']?.trim();
        });
    }

    removeRefCode() {
        this._router.navigate([], {relativeTo: this._activatedRoute, queryParams: {}});
        this.paramsSubscription?.unsubscribe()
        this.refCode = undefined;
    }

}
