<div class="container-modal"  >
    <div class="modal flex flex-col items-center" style="gap: 12px">
        <div class="stroke top"></div>
        <div class="stroke left"></div>
        <div class="stroke right"></div>
        <div class="stroke bottom"></div>

        <span class="title centerText">Incorrect network detected</span>

        <span class="description centerText">
           Your current network is not supported. Please connect to the {{chain.name}} to continue.
        </span>

        <button class="pointer" style="background: #E91898; color: white; padding: 8px 20px; margin-top: 32px" (click)="$event.stopImmediatePropagation(); switchNetwork()">
            {{chain.name}}
        </button>
        <span style="color: #293A7B">-- or --</span>
        <button class="pointer" style="background: rgba(255,255,255,0); padding: 8px 20px; color: black" (click)="$event.stopImmediatePropagation(); logout()">
            DISCONNECT
        </button>
    </div>
</div>

