import {Component, OnInit} from '@angular/core';
import {Page1Component} from "../page-1/page-1.component";

@Component({
    selector: 'app-page-4',
    standalone: true,
    imports: [],
    templateUrl: './page-4.component.html',
    styleUrls: ['../page-1/page-1.component.css', './page-4.component.css']
})

export class Page4Component extends Page1Component implements OnInit {

    override originalText = "And receive resources & assets for the future game release.";

}
