<div class="game-modal">
    <img src="/assets/games/ic-close%201.png" height="58"  class="close-button" (click)="closeModal()">
    <div class="spaceship-backdrop">
        <span class="game-title">How many light years can this old spacecraft still travel?</span>
        <div class="spaceship-container">
            <div class="input-container">
                <div class="input-zone">
                    <img src="/assets/games/min.png" class="number-button minus"  [class.disabled]="isPending" (click)="remove()">
                    <input type="number" [formControl]="valueControl" [disabled]="isPending" readonly>
                    <img src="/assets/games/plus.png"  class="number-button plus"  [class.disabled]="isPending" (click)="add()">
                </div>
                <div class="button-confirm" (click)="checkWin()" [class.disabled]="isPending"></div>
            </div>
            <div class="spaceship-zone">
                <img src="/assets/games/spaceship.png" width="140" *ngIf="!isPending">
                <img src="/assets/games/spaceship-animation.gif" width="140" *ngIf="isPending" class="spaceship" [class.gone]="!!reward">
                <div class="spaceship-display" [class.gone]="!!reward">
                    <ng-container *ngIf="reward">
                        <span class="answer-number">+{{reward}} {{reward == 1 ? 'Shard' : 'Shards'}}</span>
                        <img src="/assets/games/SHARDS.png" height="32" class="shard-icon">
                    </ng-container>
                    <ng-container *ngIf="!reward && isPending">
                        Pending transaction...
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
