import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {GameService} from "../../core/services/game.service";
import {watchContractEvent} from "@wagmi/core";
import {config} from "../../core/utils/web3modal";
import {environment} from "../../../environments/environment";
import {SessionQuery} from "../../core/stores/session/session.query";

@Component({
  selector: 'app-wheel',
  standalone: true,
  imports: [
    NgForOf,
    NgIf
  ],
  templateUrl: './wheel.component.html',
  styleUrl: './wheel.component.css'
})
export class WheelComponent {

  @Output() refresh = new EventEmitter();
  @Output() close = new EventEmitter();
  @Input() prizes: number[] = [];

  spin = false;
  complete = false;
  winner = 0;
  spinListener: any;

  constructor(
      private _service: GameService,
      private _session: SessionQuery
  ) {
  }

  async spinWheel(): Promise<void> {
    if (this.spin || this.complete) {
      return;
    } else {
      this.complete = false;
      this.spin = true;
      this.spinListener = watchContractEvent(config, {
        address: environment.wheelContract,
        abi: environment.wheelAbi,
        eventName: 'SpinResult',
        onLogs: (logs: any) => {
          const confirm = logs.find((e: any) => e.args.user == this._session.address);
          if (confirm) {
            this.winner = this.prizes.findIndex(p => p == confirm.args.reward);
            this.spin = false;
            this.complete = true;
            this.refresh.emit();
            this.spinListener();
          }
        },
        onError: (error: any) => {
          this.spin = false;
          this.spinListener();
        }
      } as any);
      const result = await this._service.spinWheel();
      if (!result.success) {
        this.spin = false;
        this.spinListener();
      }
    }
  }


  closeModal(): void {
    if(this.spin) {
      return;
    }
    this.close.emit();
  }
}
