import { Component } from '@angular/core';

@Component({
  selector: 'app-loader-brigade',
  standalone: true,
  imports: [],
  templateUrl: './loader-brigade.component.html',
  styleUrl: './loader-brigade.component.css'
})
export class LoaderBrigadeComponent {

}
