import {Component, ElementRef, HostListener, Input} from '@angular/core';
import {WalletButtonComponent} from "../wallet-button/wallet-button.component";
import {SessionQuery} from "../../core/stores/session/session.query";
import {AsyncPipe, NgIf} from "@angular/common";
import {ConnectorService} from "../../core/services/connector.service";
import {HomeData, LeaderboardData} from "../../core/stores/session/models";

@Component({
  selector: 'app-toolbar',
  standalone: true,
  imports: [
    WalletButtonComponent,
    NgIf,
    AsyncPipe
  ],
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.css'
})
export class ToolbarComponent {

  @Input() homeData: HomeData | undefined;
  @Input() leaderboardData: LeaderboardData | undefined;

  showMenu = false;
  showMobileMenu = false;
  address$ = this._session.address$;

  constructor(
      private eRef: ElementRef,
      private _session: SessionQuery,
      protected connectorService: ConnectorService,
  ) {
  }

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.closeMenu();
      this.showMobileMenu = false;
    }
  }

  connect2() {
    this.connectorService.connect2();
  }

  logout() {
    this.connectorService.logout()
  }

  closeMenu(): void {
    this.showMenu = false;
  }

  toggleMenu(): void {
    this.showMenu = !this.showMenu;
  }

}
