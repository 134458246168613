import {catchError, Observable, of, share, throwError} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LogService} from "./log.service";
import {map} from "rxjs/operators";


export class BaseService {

  protected baseURl = '';

  constructor(
    protected _http: HttpClient,
    protected _log: LogService,
  ) {
  }

  protected get<T>(path: string, preventError = true, headers?: HttpHeaders): Observable<any> {
    return this._http.get<T>(this.baseURl + path, {headers}).pipe(
      share(),
      catchError(response => {
        return preventError ? of([]) : throwError(response);
      }),
      map((response: any) => response && response.data ? response.data : response)
    );
  }

  protected getFullUrl<T>(url: string, preventError = true, headers?: HttpHeaders): Observable<any> {
    return this._http.get<T>(url, {headers}).pipe(
      share(),
      catchError(response => {
        return preventError ? of([]) : throwError(response);
      }),
      map((response: any) => response && response.data ? response.data : response)
    );
  }

  protected post(path: string, body: any, headers?: HttpHeaders): Observable<any> {
    return this._http.post<any>(this.baseURl + path, body, {headers}).pipe(
      share(),
      catchError(response => {
        return throwError(response.error);
      }),
      map((response: any) => response && response.content ? response.content : response)
    );
  }

  protected postFullUrl(url: string, body: any, headers?: HttpHeaders): Observable<any> {
    return this._http.post<any>(url, body, {headers}).pipe(
      share(),
      catchError(response => {
        return throwError(response.error);
      }),
      map((response: any) => response && response.content ? response.content : response)
    );
  }

  protected put(path: string, body: any = {}, setStore = false): any {
    return this._http.put<any>(this.baseURl + path, body).pipe(
      share(),
      catchError(response => {
        return throwError(response);
      }),
      map((response: any) => {
        return response && response.content ? response.content : response;
      })
    );
  }

  protected delete(path: string, setStore = false, id?: number): any {
    return this._http.delete(this.baseURl + path).pipe(
      share(),
      catchError(response => throwError((response && response.error) ? response.error : response))
    );
  }

}

