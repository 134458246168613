import {EventBus} from "../../EventBus";

export class ButtonRefCode extends Phaser.GameObjects.Container {

    private readonly buttonActive: Phaser.GameObjects.Image;
    private readonly buttonDisabled: Phaser.GameObjects.Image;

    private isActive: boolean = false;

    constructor(scene: Phaser.Scene, x: number, y: number) {
        super(scene, x, y);

        this.buttonActive = scene.add.image(0, 0, "button-ref-active")
        this.buttonDisabled = scene.add.image(0, 0, "button-ref-disabled")
        this.add([this.buttonActive, this. buttonDisabled])
        scene.add.existing(this);

        this.buttonActive.setInteractive({ cursor: 'pointer' });
        this.buttonActive.on('pointerup', () => {
            EventBus.emit('show-ref-dialog')
        }, this);

        this.setIsActive(this.isActive);
        EventBus.on('has-ref-code', (hasRefCode: boolean) => {
            this.setIsActive(!hasRefCode)
        });
    }

    setIsActive(isActive: boolean) {
        this.isActive = isActive;
        this.buttonActive.setVisible(isActive)
        this.buttonDisabled.setVisible(!isActive)
    }

}
