/** https://wagmi.sh/core/api/actions/getAccount */
export function getAccount(config) {
  const uid = config.state.current;
  const connection = config.state.connections.get(uid);
  const addresses = connection?.accounts;
  const address = addresses?.[0];
  const chain = config.chains.find(chain => chain.id === connection?.chainId);
  const status = config.state.status;
  switch (status) {
    case 'connected':
      return {
        address: address,
        addresses: addresses,
        chain,
        chainId: connection?.chainId,
        connector: connection?.connector,
        isConnected: true,
        isConnecting: false,
        isDisconnected: false,
        isReconnecting: false,
        status
      };
    case 'reconnecting':
      return {
        address,
        addresses,
        chain,
        chainId: connection?.chainId,
        connector: connection?.connector,
        isConnected: !!address,
        isConnecting: false,
        isDisconnected: false,
        isReconnecting: true,
        status
      };
    case 'connecting':
      return {
        address,
        addresses,
        chain,
        chainId: connection?.chainId,
        connector: connection?.connector,
        isConnected: false,
        isConnecting: true,
        isDisconnected: false,
        isReconnecting: false,
        status
      };
    case 'disconnected':
      return {
        address: undefined,
        addresses: undefined,
        chain: undefined,
        chainId: undefined,
        connector: undefined,
        isConnected: false,
        isConnecting: false,
        isDisconnected: true,
        isReconnecting: false,
        status
      };
  }
}
