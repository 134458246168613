<div class="game-modal">
    <img src="/assets/games/ic-close%201.png" height="58"  class="close-button" (click)="closeModal()">
    <div class="shard-backdrop">
        <img src="/assets/games/taconiano.png" class="taconian">
        <ng-container *ngIf="!isPending">
            <span class="game-title">CONGRATULATIONS!</span><div class="shards">
            <img src="/assets/games/SHARDS.png" class="shard" alt="shard" *ngFor="let n of Array(shardsNumber)">
        </div>
            <span class="result-label">
            You got <br>
            + {{shardsNumber}} {{shardsNumber == 1 ? 'SHARD' : 'SHARDS'}}
        </span>
        </ng-container>
        <span *ngIf="isPending" class="pending-label">Pending transaction...</span>

    </div>
</div>
