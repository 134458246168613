import Phaser, {Scene} from 'phaser';
import {TerrainContainer} from "./components/TerrainContainer";
import {SocialLinksContainer} from "./components/SocialLinksContainer";
import {InputModal} from "./components/InputModal";
import {EventBus} from "../EventBus";
import {ConfettiContainer} from "./components/ConfettiContainer";
import {StatusGame} from "../components/models";
import {RefContainer} from "./components/RefContainer";
import {HomeData} from "../../app/core/stores/session/models";
import {environment} from "../../environments/environment";

declare const WebFont: any;

export class Field extends Scene {

    sun1: Phaser.GameObjects.Image;
    sun2: Phaser.GameObjects.Image;

    terrain: Phaser.GameObjects.Image;
    stars: Phaser.GameObjects.Sprite;
    cloud1: Phaser.GameObjects.Image;
    cloud2: Phaser.GameObjects.Image;
    planet1: Phaser.GameObjects.Image;
    planet2: Phaser.GameObjects.Image;
    pointBoard: Phaser.GameObjects.Image;
    points: Phaser.GameObjects.Text;

    title2: Phaser.GameObjects.Image;

    earlyButton: Phaser.GameObjects.Image;
    xButton: Phaser.GameObjects.Image;

    pText: Phaser.GameObjects.Text;

    carrotTerrain: TerrainContainer;
    social: SocialLinksContainer;
    refCode: RefContainer;
    confetti: ConfettiContainer;
    groundMobile: Phaser.GameObjects.Image;

    modalBg: Phaser.GameObjects.RenderTexture;
    thankyouModal: InputModal;

    private planet1_x = 0;
    private planet2_x = 0;

    private readonly MOBILE_RATION = 0.85

    get isMobile(): boolean {
        const screenRatio = innerWidth / innerHeight
        return screenRatio < this.MOBILE_RATION;
    }

    get isTablet(): boolean {
        return window.innerWidth < 900;
    }

    constructor() {
        super('Field');
        EventBus.on('show-thank-you-page', (scene: Phaser.Scene) => {
            this.openThankYouModal();
        });
        EventBus.on('processing-tx', (scene: Phaser.Scene) => {
            this.carrotTerrain.setStatus(StatusGame.WAITING_TX, false)
        });
        EventBus.on('result-harvest', (success: boolean) => {
            if (success) {
                this.carrotTerrain.setStatus(StatusGame.CLAIMED, true);
            } else {
                this.carrotTerrain.setStatus(StatusGame.CONNECTED, true);
            }
        });
        EventBus.on('home-data', (homeData: HomeData) => {
            const timestamp = homeData.timestamp + environment.harvestCooldown;
            if (timestamp > (new Date().getTime() / 1000)) {
                this.carrotTerrain.setTimestamp(timestamp)
                this.carrotTerrain.setStatus(StatusGame.CLAIMED);
            }
        });
    }

    preload() {
        WebFont.load({
            custom: {
                families: ['Jersey20']
            }
        });
    }

    resizeEvent(): void {
        window.addEventListener("resize", () => {
            this.resizeAll();
        });

        window.addEventListener("mousemove", (e) => {
            if (this.stars) {
                var amountMovedX = (e.clientX * -0.3 / 8);
                var amountMovedY = (e.clientY * -0.3 / 8);
                this.stars.x = amountMovedX;
                this.stars.y = amountMovedY;
            }
        });
    }

    create() {
        this.createEnvironment();

        this.carrotTerrain = new TerrainContainer(
            this,
            0,
            0,
            'box-carrot',
            'carroll-sheet',
            20,
            'taco-button',
            () => {}
        );
        this.carrotTerrain.setPointerEvent();

        this.social = new SocialLinksContainer(this, window.innerWidth - 300, window.innerHeight - 100);
        this.refCode = new RefContainer(this, 0, 0);

        this.pointBoard = this.add.image(60, window.innerHeight - 40, 'progress').setVisible(false);
        this.pointBoard.setOrigin(0, 1);
        this.points = this.add.text(0, 0, '0', {color: 'white', align: 'right'}).setVisible(false);
        this.pText = this.add.text(0, 0, 'Points', {color: 'white'}).setVisible(false);
        Phaser.Display.Align.In.BottomRight(this.points, this.pointBoard, -55, -22);
        Phaser.Display.Align.In.BottomLeft(this.pText, this.pointBoard, -35, -22);

        this.confetti = new ConfettiContainer(this, 0, 0, 100);
        this.confetti.setDepth(1);

        this.setDragFunction(this.planet1);
        this.setDragFunction(this.planet2);

        this.resizeAll();
        this.resizeEvent();

        EventBus.emit('scene-field-loaded');
    }

    setDragFunction(obj: Phaser.GameObjects.Image) {
        obj.setInteractive({ draggable: true });
        obj.on('drag',  (pointer: any, dragX: any, dragY: any) => {
            obj.x = dragX;
            obj.y = dragY;

            if (Math.abs(this.planet1.x - this.planet2.x) < 10 && Math.abs(this.planet1.y - this.planet2.y) < 10) {
                this.animToCenter(this.planet1, this.planet1_x, 120)
                this.animToCenter(this.planet2, this.planet2_x, 320, () => {
                    this.planet1.setTint(0xAAAAAA);
                    this.planet2.setTint(0xAAAAAA);
                    this.terrain.setTint(0xAAAAAA);
                    this.groundMobile.setTint(0xAAAAAA);
                    this.cameras.main.setBackgroundColor('#440062');
                    this.cloud2.setTexture('cloud1-dark');
                    this.cloud1.setTexture('cloud2-dark');
                })
            }
        });
    }

    animToCenter(obj: Phaser.GameObjects.Image, resetX: number, resetY: number, onComplete?: Phaser.Types.Tweens.TweenOnCompleteCallback) {
        obj.disableInteractive();
        obj.setScale(1);
        this.tweens.add({
            targets: obj,
            x: {from: obj.x, to: this.carrotTerrain.x},
            y: {from: obj.y, to: 300},
            duration: 800,
            ease: Phaser.Math.Easing.Sine.In,
            onComplete: () => {
                this.tweens.add({
                    targets: obj,
                    scale: {from: 1, to: 2},
                    ease: Phaser.Math.Easing.Bounce.In,
                    delay: 0,
                    duration: 1500,
                    onComplete: (tween, targets, param) => {
                        if (onComplete) {
                            onComplete(tween, targets, param)
                        }
                        this.tweens.add({
                            targets: obj,
                            x: {from: obj.x, to: resetX},
                            y: {from: obj.y, to: resetY},
                            duration: 1200,
                            ease: Phaser.Math.Easing.Sine.In,
                        })
                    }
                });
            }
        });
    }

    createEnvironment(): void {
        this.sun1 = this.add.image(-288, -358, 'sun1');
        this.sun1.setDisplaySize(800, 800);
        this.sun2 = this.add.image(window.innerWidth + 400, window.innerHeight + 50, 'sun2');
        this.sun2.setDisplaySize(760, 760);
        this.sun1.setOrigin(0, 0);
        this.sun2.setOrigin(1, 1);

        this.stars = this.add.sprite(0, 0, 'stars');
        this.stars.setOrigin(0, 0);
        this.stars.displayWidth = window.innerWidth;
        this.stars.displayHeight = window.innerHeight;

        this.planet1 = this.add.image(450, 120, 'planet1');
        this.planet2 = this.add.image(window.innerWidth - 200, 320, 'planet2');
        this.planet1_x = this.planet1.x;
        this.planet2_x = this.planet2.x;

        this.cloud1 = this.add.image(220, 350, 'cloud2');
        this.cloud2 = this.add.image(window.innerWidth - 300, 120, 'cloud1');

        this.terrain = this.add.image(0, window.innerHeight + 2, 'terrain');
        this.terrain.width = 1440;
        this.terrain.setOrigin(0, 1);
        this.terrain.displayWidth = this.scale.width;

        this.groundMobile = this.add.image(0, this.scale.height, 'ground-mobile').setScale(0.5).setOrigin(0, 1).setVisible(false);

        this.tweens.add({
            targets: [this.cloud1],
            x: "+=80",
            yoyo: true,
            loop: -1,
            ease: 'linear',
            duration: 5000,
        });

        this.tweens.add({
            targets: [this.cloud2],
            x: "-=50",
            yoyo: true,
            loop: -1,
            ease: 'linear',
            duration: 4000,
        });
    }

    openModal(): void {
        EventBus.emit('show-email-dialog');
        //this.openThankYouModal();
    }

    openThankYouModal(): void {
        this.confetti.start();
        this.earlyButton.disableInteractive();
        this.modalBg = this.add.renderTexture(0, 0, window.innerWidth + 10, window.innerHeight + 10);
        this.modalBg.setOrigin(0, 0);
        this.modalBg.fill(0x000000, 0.5);
        this.thankyouModal = new InputModal(this, 0, 0, () => {
            this.modalBg.destroy();
            this.thankyouModal.destroy();
            this.earlyButton.setVisible(false);
        });
        if (this.isMobile) {
            this.thankyouModal.modal.displayWidth = window.innerWidth;
        }
    }


    override update() {
    }

    resizeAll(): void {
        this.terrain.displayWidth = window.innerWidth;
        this.social.setPosition(window.innerWidth - 300, window.innerHeight - 100);
        this.terrain.y = window.innerHeight + 2;
        this.social.setScale(1, 1);
        this.resize(window.innerWidth, window.innerHeight);

        this.stars.displayWidth = window.innerWidth;
        this.cloud1.setVisible(true);
        this.cloud2.setVisible(true);

        if (this.modalBg) {
            this.modalBg.setDisplaySize(window.innerWidth + 10, window.innerHeight + 10);
            this.thankyouModal.scale = 1;
            this.thankyouModal.setPosition(0, 0);
        }

        if (this.xButton) {
            this.xButton.scale = 1;
            //const wpoints = this.carrotTerrain.badgeImage.getWorldTransformMatrix().decomposeMatrix();
            //this.xButton.setPosition(window.innerWidth / 2, wpoints.translateY);
        }

        if (this.earlyButton) {
            this.earlyButton.scale = 0.5;
            this.earlyButton.setPosition(window.innerWidth / 2, this.xButton.y + 150);
        }

        if (this.title2) {
            this.title2.scale = 1;
        }
        if (this.isMobile) {
            this.resizeMobile();
            return;
        }
        if (this.isTablet) {
            this.resizeTablet();
            return;
        } else {
            this.sun2.setPosition(window.innerWidth + 400, window.innerHeight + 50);
            this.cloud2.x = window.innerWidth - 300;
            this.planet2.x = window.innerWidth - 200;
        }
    }

    resize(innerWidth: number, innerHeight: number) {
        const centerX = innerWidth / 2;
        if (!this.social) {
            return;
        }

        const groundHeight = innerHeight * 0.45;
        const screenRatio = innerWidth / innerHeight

        const isMobile = screenRatio < this.MOBILE_RATION;
        this.refCode.resize(isMobile);

        if (!isMobile) {
            const distanceFromCenter = innerWidth * 0.2;
            this.carrotTerrain.setX(centerX);

            this.groundMobile.setVisible(false);
            this.terrain.setVisible(true);
            this.terrain.displayWidth = this.scale.width * 1.5
            this.terrain.setY(innerHeight * 1.01);

            const groundHeight = innerHeight * 0.4;
            this.terrain.displayHeight = groundHeight;

            const scaleFactor = this.terrain.displayHeight / this.terrain.height * 0.8;

            const footerPadding = innerWidth * 0.1; // * (1 -  Math.min(scaleFactor, 1));
            this.social.setScale(scaleFactor);
            this.social.setY(innerHeight - (groundHeight) + (groundHeight / 1.4));
            this.social.setX(innerWidth - (this.social.getGeneratedWidth() * scaleFactor) - footerPadding + (35 * scaleFactor));

            const scaleFactorRefCode = scaleFactor * 1.2
            this.refCode.setScale(scaleFactorRefCode);
            const yRefCode = innerHeight - (groundHeight) + (groundHeight / 1.4) + (35 * scaleFactorRefCode);
            this.refCode.setY(yRefCode)
            this.refCode.setX(footerPadding + 50);

            const terrainY = innerHeight - groundHeight;
            this.carrotTerrain.setScale(scaleFactor);
            this.carrotTerrain.setY(terrainY);
            this.refCode.showCables(true);

            if (this.thankyouModal) {
                this.thankyouModal.scale = 1;
            }
        } else {
            this.groundMobile.setVisible(true);
            this.terrain.setVisible(false);

            if (this.thankyouModal) {
                this.thankyouModal.scale = 0.7;
            }

            this.groundMobile.displayWidth = this.scale.width * 1.5;
            this.groundMobile.setY(innerHeight * 1.01);

            this.groundMobile.displayHeight = groundHeight;

            let scaleFactor = this.groundMobile.displayWidth / this.groundMobile.width * 0.8;

            let scaleFactorFooter = scaleFactor;
            if (screenRatio > 0.45) {
                scaleFactorFooter = scaleFactorFooter * 0.8;
            }
            const scaleFactorSocial = Math.min(1, scaleFactor)
            this.social.setScale(scaleFactorSocial);
            this.social.setX(centerX - (this.social.getGeneratedWidth() * scaleFactorSocial / 2));
            //this.socialLinksContainer.setY(innerHeight - (groundHeight) + (groundHeight / 1.5));
            this.social.setY(innerHeight - (60 * scaleFactorSocial)/2 - (groundHeight * 0.15));

            const scaleFactorRefCode = Math.min(1, scaleFactor * 1.1);
            this.refCode.setScale(scaleFactorRefCode)
            this.refCode.setX(centerX);
            this.refCode.setY(innerHeight - (80 * scaleFactorRefCode) / 2 - (groundHeight * 0.55));

            //const terrainY = innerHeight - groundHeight;

            const terrainScaleFactor = scaleFactor * 0.7;
            const terrainY = innerHeight - (300 * terrainScaleFactor) / 2 - (groundHeight * 0.75);
            this.carrotTerrain.setScale(terrainScaleFactor);
            this.carrotTerrain.setY(terrainY)

            this.carrotTerrain.setX(centerX);
            this.refCode.showCables(false);
        }
    }

    resizeTablet(): void {

        this.sun1.setDisplaySize(800, 800);

        this.sun2.setPosition(window.innerWidth + 400, window.innerHeight + 50);
        this.sun2.setDisplaySize(760, 760);

        this.sun1.x = -450;
        this.sun1.y = -450;
        this.cloud1.y = 300;
        this.cloud1.x = 40;
        this.cloud2.x = window.innerWidth - 20;
        this.cloud2.y = 150;
        this.planet1.x = 100;
        this.planet2.x = window.innerWidth - 100;
        this.planet1_x = this.planet1.x;
        this.planet2_x = this.planet2.x;
        if (this.xButton) {
            this.xButton.scale = 0.7;
        }

    }

    resizeMobile(): void {
        this.sun1.setDisplaySize(500, 500);
        this.sun1.x = -250;
        this.sun1.y = -250;
        this.sun2.setDisplaySize(500, 500);
        this.sun2.y = window.innerHeight / 2 + 280;
        this.sun2.x = window.innerWidth + 250;
        this.cloud1.setVisible(false);
        this.cloud2.setVisible(false);
        this.planet1.x = 0;
        this.planet2.x = window.innerWidth;
        this.planet2.y = 200;
        this.planet1_x = this.planet1.x;
        this.planet2_x = this.planet2.x;
        this.terrain.displayHeight = 350;
        this.stars.displayWidth = 800;
        if (this.title2) {
            this.title2.scale = 0.6;
        }

       /* const badgeBounds = this.carrotTerrain.badgeImage.getBounds();
        const newX = (-this.carrotTerrain.x + window.innerWidth / 2) / this.carrotTerrain.scale;
        this.carrotTerrain.badgeImage.x = newX;

        const wpoints = this.carrotTerrain.badgeImage.getWorldTransformMatrix().decomposeMatrix();

        if (this.xButton) {
            this.xButton.scale = 0.7;
            this.xButton.setPosition(window.innerWidth / 2, wpoints.translateY + this.carrotTerrain.badgeImage.getBounds().height);
        }*/
    }

    setOverEffect(target: Phaser.GameObjects.Image) {
        const y = target.y;
        target.on('pointerover', () => {
            this.add.tween({
                targets: [target],
                y: y - 10,
                duration: 300
            });
        });
        target.on('pointerout', () => {
            this.add.tween({
                targets: [target],
                y: y,
                duration: 300
            });
        });
    }
}
