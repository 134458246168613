import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {Page1Component} from "./page/page-1/page-1.component";
import {Page2Component} from "./page/page-2/page-2.component";
import {NgClass, NgIf} from "@angular/common";
import {Page3Component} from "./page/page-3/page-3.component";
import {Page4Component} from "./page/page-4/page-4.component";
import {Page5Component} from "./page/page-5/page-5.component";
import {SessionQuery} from "../../core/stores/session/session.query";
import {SessionStore} from "../../core/stores/session/session.store";

@Component({
    selector: 'app-tutorial',
    standalone: true,
    imports: [
        RouterOutlet,
        Page1Component,
        Page2Component,
        NgIf,
        Page3Component,
        Page4Component,
        Page5Component,
        NgClass
    ],
    templateUrl: './tutorial.component.html',
    styleUrl: './tutorial.component.css'
})
export class TutorialComponent implements OnInit{

    @Output() onClose = new EventEmitter<void>();
    @Output() onMoreInfo = new EventEmitter<void>();

    index = 0;

    constructor(
        private _session: SessionQuery,
        private _sessionStore: SessionStore,
    ) {

    }

    ngOnInit() {
        const element = document?.getElementById('modal-tutorial');
        if (element) {
            element.addEventListener('pointerdown', function(event) {
                event.preventDefault();  // Previene il comportamento predefinito (utile per touch o eventi di trascinamento)
                event.stopPropagation(); // Blocca la propagazione dell'evento agli elementi sottostanti
            });
        }
    }


    close() {
        this.onClose.emit();
        this._sessionStore.update({isTutorialWatched: true})
    }

    onContinue() {
        if (this.index >= 4) {
            this.close();
            return;
        }
        this.index += 1;
    }

}
