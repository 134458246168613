import {Injectable} from "@angular/core";
import {
    disconnect,
    getAccount, GetAccountReturnType,
    readContract,
    reconnect, switchNetwork, waitForTransaction,
    watchAccount, writeContract
} from "@wagmi/core";
import {config, getNetworkId, web3Modal} from "../utils/web3modal";
import {SessionQuery} from "../stores/session/session.query";
import {SessionStore} from "../stores/session/session.store";
import {BehaviorSubject, Subject} from "rxjs";
import {environment} from "../../../environments/environment";
import {EventBus} from "../../../game/EventBus";
import {MetaMaskSDK} from "@metamask/sdk";
import {CacheResponseService} from "./cache-response.service";
import {ToastService, ToastType} from "./toast.service";


@Injectable({providedIn: 'root'})
export class ConnectorService {

    private readonly _isConnecting$ = new BehaviorSubject<boolean>(false);
    public isConnecting$ = this._isConnecting$.asObservable()

    private readonly _watchAccount$ = new Subject<GetAccountReturnType<typeof config>>();
    public watchAccount$ = this._watchAccount$.asObservable()


    constructor(
        private _session: SessionQuery,
        private _sessionStore: SessionStore,
        private _cacheResponseService: CacheResponseService,
        private _toastService: ToastService,
    ) {
        reconnect(config).then(s => {
            console.log('reconnect: ', s)
        }).catch(e => {
            console.error('reconnect: ', e)
        })

        watchAccount(config, {
            onChange: (account, prev) => {
                this._watchAccount$.next(account)
                console.log('Account changed!, isConnected: ' + account.isConnected, account + ', chain: ' + account.chain?.name);
                this._isConnecting$.next(account.isConnecting);
                if (account.isConnected) {
                    _sessionStore.update({address: account.address!.toString()});
                } else {
                    _sessionStore.logout();
                }
                EventBus.emit('isConnected', account.isConnected)
                EventBus.emit('address', account.isConnected ? account.address : null);
            },
        });
    }

    sendAccountToPhaser() {
        EventBus.emit('isConnected', getAccount(config).isConnected)
        EventBus.emit('address', getAccount(config).address);
    }

    async connect(): Promise<any> {
        if (getAccount(config).isConnected) {
            disconnect(config)
        } else if (this._isConnecting$.value) {
            disconnect(config)
        } else {
            web3Modal.open()
        }
    }

    async connect2(): Promise<any> {
        const sdk = new MetaMaskSDK({
            dappMetadata: {
                name: "Example JavaScript Dapp",
                url: window.location.href,
            },
            // Other options
        });
        await sdk.init();
        try {
            const ethereum = await sdk.connect();
            console.log('ethereum success');
            console.log('ethereum success: ', ethereum);
            alert('logged: ' + ethereum )
        } catch (e: any) {
            console.error('ethereum error: ', e?.message ?? e?.shortMessage ?? 'error');
        }
    }

    switchNetwork() {
        switchNetwork(config, {chainId: getNetworkId().id}).then(

        ).catch(e => {
            //todo show toast
            console.error(e)
        })
    }

    logout() {
        this._cacheResponseService.clearAll();
        disconnect(config)
        EventBus.emit('disconnected');
    }

    async readPoints(): Promise<any> {
        const result = await readContract(config, {
            address: environment.clickerContract as any,
            abi: environment.clickerAbi,
            functionName: 'users_points',
            args: [this._session.address]
        });
        return Number(result);
    }

    async readReferral(): Promise<any> {
        const result = await readContract(config, {
            address: environment.clickerContract as any,
            abi: environment.clickerAbi,
            functionName: 'referrals',
            args: [this._session.address]
        });
        return Number(result);
    }

    async readTimestamp(): Promise<any> {
        const result = await readContract(config, {
            address: environment.clickerContract as any,
            abi: environment.clickerAbi,
            functionName: 'users_timestamps',
            args: [this._session.address]
        });
        return Number(result);
    }

    async harvest(callbackSigned: () => void): Promise<{ success: boolean }> {
        try {
            const obj = {
                abi: environment.clickerAbi,
                address: environment.clickerContract,
                functionName: 'harvest',
                args: [],
                confirmations: 1,
            };
            const result = await this.signTransaction([obj], callbackSigned);
            if (result?.success) {
                return {success: true};
            }
        } catch (e) {
        }
        return {success: false};
    }

    public async linkReferralCode(address: string) {
        const obj = {
            abi: environment.clickerAbi,
            address: environment.clickerContract,
            functionName: 'link',
            args: [address],
            confirmations: 1,
        };
        return await this.signTransaction([obj]);
    }



    async signTransaction(array: any[], callbackSigned: () => void = () => {}) {
        let successCount = 0;
        const hashArray: string[] = [];
        let error: string | undefined;
        try {
            for(let i = 0; i < array.length; i++) {
                const writeItem = array[i];
                const hash = await writeContract(config, writeItem);
                callbackSigned();
                const transactionReceipt = await waitForTransaction(config,{
                    chainId: getNetworkId().id,
                    hash: hash,
                    confirmations: writeItem.confirmations || 1
                });
                console.log('hash', hash);
                hashArray.push(hash);
                successCount++;
            }
        } catch (e: any) {
            error = e?.shortMessage ?? e?.message
            if (error && !error.includes('User rejected the request')) {
                this._toastService.open(error ?? '', ToastType.ERROR);
            }
            console.error('[signTransaction] error: ', e)
        }
        return {
            success: successCount == array.length,
            success_count: successCount,
            tot_count: array.length,
            hash_array: hashArray,
            error: error
        }
    }

}
