import { encodeEventTopics } from '../../utils/abi/encodeEventTopics.js';
import { numberToHex } from '../../utils/encoding/toHex.js';
import { createFilterRequestScope } from '../../utils/filters/createFilterRequestScope.js';
/**
 * Creates a Filter to retrieve event logs that can be used with [`getFilterChanges`](https://viem.sh/docs/actions/public/getFilterChanges) or [`getFilterLogs`](https://viem.sh/docs/actions/public/getFilterLogs).
 *
 * - Docs: https://viem.sh/docs/contract/createContractEventFilter
 *
 * @param client - Client to use
 * @param parameters - {@link CreateContractEventFilterParameters}
 * @returns [`Filter`](https://viem.sh/docs/glossary/types#filter). {@link CreateContractEventFilterReturnType}
 *
 * @example
 * import { createPublicClient, http, parseAbi } from 'viem'
 * import { mainnet } from 'viem/chains'
 * import { createContractEventFilter } from 'viem/contract'
 *
 * const client = createPublicClient({
 *   chain: mainnet,
 *   transport: http(),
 * })
 * const filter = await createContractEventFilter(client, {
 *   abi: parseAbi(['event Transfer(address indexed, address indexed, uint256)']),
 * })
 */
export async function createContractEventFilter(client, parameters) {
  const {
    address,
    abi,
    args,
    eventName,
    fromBlock,
    strict,
    toBlock
  } = parameters;
  const getRequest = createFilterRequestScope(client, {
    method: 'eth_newFilter'
  });
  const topics = eventName ? encodeEventTopics({
    abi,
    args,
    eventName
  }) : undefined;
  const id = await client.request({
    method: 'eth_newFilter',
    params: [{
      address,
      fromBlock: typeof fromBlock === 'bigint' ? numberToHex(fromBlock) : fromBlock,
      toBlock: typeof toBlock === 'bigint' ? numberToHex(toBlock) : toBlock,
      topics
    }]
  });
  return {
    abi,
    args,
    eventName,
    id,
    request: getRequest(id),
    strict: Boolean(strict),
    type: 'event'
  };
}
