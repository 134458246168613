import { writeContract as viem_writeContract } from 'viem/actions';
import { getAction } from '../utils/getAction.js';
import { getAccount } from './getAccount.js';
import { getConnectorClient } from './getConnectorClient.js';
import { simulateContract } from './simulateContract.js';
/** https://wagmi.sh/core/api/actions/writeContract */
export async function writeContract(config, parameters) {
  const {
    account,
    chainId,
    connector,
    __mode,
    ...rest
  } = parameters;
  let client;
  if (typeof account === 'object' && account.type === 'local') client = config.getClient({
    chainId
  });else client = await getConnectorClient(config, {
    account,
    chainId,
    connector
  });
  const {
    connector: activeConnector
  } = getAccount(config);
  let request;
  if (__mode === 'prepared' || activeConnector?.supportsSimulation) request = rest;else {
    const {
      request: simulateRequest
    } = await simulateContract(config, {
      ...rest,
      account,
      chainId
    });
    request = simulateRequest;
  }
  const action = getAction(client, viem_writeContract, 'writeContract');
  const hash = await action({
    ...request,
    ...(account ? {
      account
    } : {}),
    chain: chainId ? {
      id: chainId
    } : null
  });
  return hash;
}
