<div class="game-modal">
    <img src="/assets/games/ic-close%201.png" height="58"  class="close-button" (click)="closeModal()">
    <div class="wheel-modal-content">
        <span class="game-title">{{complete ? 'YOUR REWARD!' : 'SPIN THE WHEEL!'}}</span>
        <img src="/assets/games/taconiano.png" class="taconian">
        <div class="wheel-container">
            <button class="spin-button" (click)="spinWheel()" [class.spin]="spin"  [class.disabled]="complete">
                <span class="spin-label">{{spin ? 'loading...' : 'spin'}}</span>
                <img src="/assets/games/button-spin.png" alt="spin">
            </button>
            <div class="wheel-frame"></div>
            <div class="wheel" [style]="'--items:' + prizes.length + '; --win:' + (winner-1)" [class.spin]="spin" [class.win]="complete">
                <div class="slice"
                     [style]="'--i:' + i"
                     *ngFor="let n of prizes; let i = index">
                    <div class="slice-label">
                        <span>+{{n}}</span>
                        <img src="/assets/games/SHARDS.png" height="20" alt="SHARDS">
                    </div>
                </div>
        </div>
        </div>
    </div>
</div>
