import {Injectable} from "@angular/core";
import {readContract,} from "@wagmi/core";
import {config} from "../utils/web3modal";
import {environment} from "../../../environments/environment";
import {ConnectorService} from "./connector.service";
import {ToastService, ToastType} from "./toast.service";
import {BackendTacoService} from "./backend-taco.service";
import {MetadataResponse, ShopItem, ShopProductResponse} from "../models/models";


@Injectable({providedIn: 'root'})
export class ShopService {

    constructor(
        private _connectorService: ConnectorService,
        private _toastService: ToastService,
        protected _backendTacoService: BackendTacoService,
    ) {
    }

    async readPoints(address: string): Promise<number> {
        const result = await readContract(config, {
            address: environment.clickerContract as any,
            abi: environment.clickerAbi,
            functionName: 'users_points',
            args: [address]
        });
        return Number(result);
    }

    async readReferral(address: string): Promise<any> {
        const result = await readContract(config, {
            address: environment.clickerContract as any,
            abi: environment.clickerAbi,
            functionName: 'referrals',
            args: [address]
        });
        return result;
    }

    async readTimestamp(address: string): Promise<number> {
        const result = await readContract(config, {
            address: environment.clickerContract as any,
            abi: environment.clickerAbi,
            functionName: 'users_timestamps',
            args: [address]
        });
        return Number(result);
    }

    public async getNextProductId(): Promise<number> {
        const result = await readContract(config, {
            address: environment.shopContract as any,
            abi: environment.shopAbi,
            functionName: 'nextProductId',
            args: []
        });
        return Number(result);
    }

    public async getProductsRaw(): Promise<ShopProductResponse[]> {
        return await this.readContractArray<any>(config, {
            address: environment.shopContract as any,
            abi: environment.shopAbi,
            functionName: 'getProducts',
            args: [0, Number.MAX_SAFE_INTEGER]
        });
    }

    public async getProducts(): Promise<ShopItem[]> {
        const products = (await this.getProductsRaw()).filter(item => item.isActive);
        let result: MetadataResponse[] = await this._backendTacoService.getMetadata(products.map((p) => p.contractAddress));
        return products.map((p) => {
            const metadata = result.find((m) => m.contract_address.toLowerCase() === p.contractAddress.toLowerCase() && m.type === p.typeId);
            return {
                ...p,
                name: metadata?.name ?? '',
                image: metadata?.image ?? '',
            };
        });
    }

    private async readContractArray<T>(config: any, parameters: any): Promise<T[]> {
        try {
            return await readContract(config, parameters) as any;
        } catch (e: any) {
            this._toastService.open(e?.shortMessage ?? e?.message, ToastType.ERROR);
            return [];
        }
    }

    public async claimProduct(address: string, productId: number, callbackSigned: () => void = () => {}): Promise<{ success: boolean }> {
        try {
            const obj = {
                abi: environment.shopAbi,
                address: environment.shopContract,
                functionName: 'claimProduct',
                args: [productId],
                confirmations: 1,
            };
            const result = await this._connectorService.signTransaction([obj], callbackSigned);
            if (result?.success) {
                return {success: true};
            }
        } catch (e: any) {

        }
        return {success: false};
    }

    async harvest(callbackSigned: () => void): Promise<{ success: boolean }> {
        try {
            const obj = {
                abi: environment.clickerAbi,
                address: environment.clickerContract,
                functionName: 'harvest',
                args: [],
                confirmations: 1,
            };
            const result = await this._connectorService.signTransaction([obj], callbackSigned);
            if (result?.success) {
                return {success: true};
            }
        } catch (e) {
        }
        return {success: false};
    }

    public async linkReferralCode(address: string): Promise<{ success: boolean }> {
        try {
            const obj = {
                abi: environment.clickerAbi,
                address: environment.clickerContract,
                functionName: 'link',
                args: [address],
                confirmations: 1,
            };
            const result = await this._connectorService.signTransaction([obj]);
            if (result?.success) {
                return {success: true};
            }
        } catch (e) {
        }
        return {success: false};
    }

}
