import { Scene } from 'phaser';

export class Preloader extends Scene {

    constructor () {
        super('Preloader');
        window.addEventListener('resize', () => {
            this.resize(window.innerWidth, window.innerHeight)
        });
    }

    private rectangleBorder: Phaser.GameObjects.Rectangle;
    private bar: Phaser.GameObjects.Rectangle;


    init() {
        //  A simple progress bar. This is the outline of the bar.
        this.rectangleBorder = this.add.rectangle(512, 384, 468, 32).setStrokeStyle(2, 0xffffff);
        //  This is the progress bar itself. It will increase in size from the left based on the % of progress.
        this.bar = this.add.rectangle(512-230, 384, 4, 28, 0xffffff);
        //  Use the 'progress' event emitted by the LoaderPlugin to update the loading bar
        this.load.on('progress', (progress: number) => {
            //  Update the progress bar (our bar is 464px wide, so 100% = 464px)
            this.bar.width = 4 + (460 * progress);

        });
        this.resize(this.scale.width, this.scale.height);
    }

    resize(innerWidth: number, innerHeight: number) {
        const centerX = innerWidth / 2;
        const centerY = innerHeight / 2;

        const scaleFactor = innerWidth / 1024 * ((innerWidth < 600) ? 1.5 : 0.7);
        this.rectangleBorder?.setScale(scaleFactor);
        this.bar.setScale(scaleFactor);

        this.rectangleBorder?.setX(centerX);
        this.bar.setX(centerX-230 * scaleFactor);
        this.rectangleBorder?.setY(centerY);
        this.bar.setY(centerY);
    }

    preload () {
        this.load.setPath('assets');
        this.load.image('sun1', 'violet-circle.png');
        this.load.image('sun2', 'blue-circles.png');
        this.load.image('terrain', 'ground+terrain.png');
        this.load.image('stars', 'stars.png');

        this.load.image('box-corn', 'corn-terrain.png');
        this.load.image('carrot-terrain-green', 'carrot-terrain-green.png');
        this.load.image('box-carrot', 'carrot-terrain.png');
        this.load.image('bubble', 'bubble.png');
        this.load.image('taco-button', 'button.png');
        this.load.image('taico-button', 'taiko.png');
        this.load.image('carrot-terrain', 'carrot-terrain-2.png');
        this.load.image('carrot', 'carrot-pixel.png');
        this.load.image('corn', 'corn-pixel.png');
        this.load.image('arrow', 'arrow.png');
        this.load.image('title', 'Harvest.png');
        this.load.image('planet1', 'planet1.png');
        this.load.image('planet2', 'planet2.png');
        this.load.image('cloud1', 'cloud.png');
        this.load.image('cloud2', 'cloud2.png');
        this.load.image('cloud1-dark', 'cloud-1-dark.png');
        this.load.image('cloud2-dark', 'cloud-2-dark.png');
        this.load.image('x', 'x.png');
        this.load.image('confetti-1', 'confetti-1.png');
        this.load.image('confetti-2', 'confetti-2.png');
        this.load.image('confetti-3', 'confetti-3.png');
        this.load.image('confetti-4', 'confetti-4.png');
        this.load.image('confetti-5', 'confetti-5.png');
        this.load.image('coming-soon', 'COMINGSOON.png');
        this.load.image('early-button', 'early2.png');
        this.load.image('modal', 'modal.png');
        this.load.image('close-button', 'button-close.png');
        this.load.image('input-bg', 'input-bg.png');
        this.load.image('confirm-button', 'confirm-button2.png');
        this.load.image('progress', 'progress.png');
        this.load.image('button-discord', 'button-discord.png');
        this.load.image('button-whitepaper', 'button-whitepaper.png');
        this.load.image('button-twitter', 'button-twitter.png');
        this.load.image('button-website', 'button-website.png');
        this.load.image('button-info', 'button-info.png');
        this.load.image('progress-selected-left', 'progress-selected-left.png');
        this.load.image('progress-selected-right', 'progress-selected-right.png');
        this.load.image('plus-500', 'plus-500.png');
        this.load.image('ground-mobile', 'ground-mobile.png');
        this.load.image('button-link-discord', 'discord-button.png');
        this.load.image('chat', 'chat.png');
        this.load.image('stand-time', 'stand-time.png');
        this.load.image('blob-small', 'blob-small.png');

        this.load.image('bg-box-ref', 'bg-box-ref.png');
        this.load.image('ref-cable-bottom', 'ref-cable-bottom.png');
        this.load.image('ref-cable-top', 'ref-cable-top.png');
        this.load.image('ic-boost', 'ic-boost.png');

        this.load.image('button-ref-active', 'button-ref-active.png');
        this.load.image('button-ref-disabled', 'button-ref-disabled.png');

        this.load.spritesheet('carrotA', 'carroll_sheet.png', { frameWidth: 128, frameHeight: 128, endFrame: 4 });

        this.load.spritesheet('stoner-sheet', 'stoner-sheet.png', { frameWidth: 128, frameHeight: 128, endFrame: 4 });
        this.load.spritesheet('peene-sheet', 'peene-sheet.png', { frameWidth: 128, frameHeight: 128, endFrame: 4 });
        this.load.spritesheet('korn-sheet', 'korn-sheet.png', { frameWidth: 128, frameHeight: 128, endFrame: 4 });
        this.load.spritesheet('ceppi-sheet', 'ceppi-sheet.png', { frameWidth: 128, frameHeight: 128, endFrame: 4 });
        this.load.spritesheet('bohtato-sheet', 'bohtato-sheet.png', { frameWidth: 128, frameHeight: 128, endFrame: 4 });
        this.load.spritesheet('carroll-sheet', 'carroll-sheet.png', { frameWidth: 128, frameHeight: 128, endFrame: 4 });
        this.load.spritesheet('tundis-sheet', 'tundis-sheet.png', { frameWidth: 128, frameHeight: 128, endFrame: 4 });

        this.load.script('webfont', 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js');
    }

    create () {
        this.scene.start('Field');
    }
}
