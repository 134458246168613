import {BaseService} from "./base.service";
import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import {getAuth, signInAnonymously} from "firebase/auth";
import {LogService} from "./log.service";
import {LocalStorageService} from "./local-storage.service";
import {environment} from "../../../environments/environment";
import {MetadataResponse} from "../models/models";


@Injectable({providedIn: 'root'})
export class BackendTacoService extends BaseService {

    constructor(
        http: HttpClient,
        log: LogService,
        private _localStorageService: LocalStorageService,
    ) {
        super(http, log);
        this.baseURl = environment.backendUrl;
    }

    async getMetadata(contracts: string[]): Promise<MetadataResponse[]> {
        //transform to lowercase and remove duplicates
        contracts = contracts.map(c => c.toLowerCase()).filter((value, index, self) => self.indexOf(value) === index);
        return await firstValueFrom(this.get(`metadata?contract[]=${contracts.join('&contract[]=')}`));
    }

}
