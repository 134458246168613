import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {watchContractEvent} from "@wagmi/core";
import {config} from "../../core/utils/web3modal";
import {environment} from "../../../environments/environment";
import {GameService} from "../../core/services/game.service";
import {SessionQuery} from "../../core/stores/session/session.query";

@Component({
  selector: 'app-check-in',
  standalone: true,
  imports: [
    NgForOf,
    NgIf
  ],
  templateUrl: './check-in.component.html',
  styleUrl: './check-in.component.css'
})
export class CheckInComponent implements OnInit{

  @Input() shardsNumber = 0;
  @Output() close = new EventEmitter<boolean>();
  @Output() refresh = new EventEmitter();

  protected readonly Array = Array;
  isPending = true;
  shardsListener: any;

  constructor(
      private _service: GameService,
      private _session: SessionQuery,
  ) {
  }

  ngOnInit() {
    this.claimShards();
  }

  async claimShards(): Promise<any> {
    this.isPending = true;
    this.shardsListener = watchContractEvent(config, {
      address: environment.checkinContract,
      abi: environment.checkingAbi,
      eventName: 'CheckinDone',
      onLogs: (logs: any) => {
        const confirm = logs.find((e: any) => e.args.user == this._session.address);
        if (confirm) {
          this.shardsListener();
          this.refresh.emit();
          this.isPending = false;
        }
      },
      onError: (error: any) => {
        this.close.emit();
        this.shardsListener();
      }
    } as any);
    const result = await this._service.claimShards();
    if (!result.success) {
      this.close.emit();
    }
  }


  closeModal(): void {
    if(this.isPending) {
      return;
    }
    this.close.emit();
  }

}
