import {Injectable} from "@angular/core";
import {SessionQuery} from "../stores/session/session.query";
import {ClickerService} from "./clicker.service";
import {HomeData, LeaderboardData} from "../stores/session/models";
import {getLevel} from "../utils/functions";
import {BaseService} from "./base.service";
import {HttpClient} from "@angular/common/http";
import {LogService} from "./log.service";
import {firstValueFrom} from "rxjs";
import {CacheResponseService} from "./cache-response.service";
import {GameService} from "./game.service";


@Injectable({providedIn: 'root'})
export class HomeService extends BaseService{

    constructor(
        http: HttpClient,
        log: LogService,
        private _session: SessionQuery,
        protected clickerService: ClickerService,
        protected _gameService: GameService,
        protected _cacheResponseService: CacheResponseService,
    ) {
        super(http, log);
    }

    async getAllInfo(): Promise<HomeData> {
        const address = this._session.address;
        if (!address) {
            throw Error("User not logged");
        }
        const points = await this.clickerService.readPoints(address);
        const shards = await this._gameService.checkUserShards(address);
        const timestamp = await this.clickerService.readTimestamp(address);
        const referral = await this.clickerService.readReferral(address);
        const hasRef = referral.toString() !== '0x0000000000000000000000000000000000000000';
        const capsuleGame = await this._gameService.checkCapsule();
        const shardGame = await this._gameService.checkShards();
        const journeyGame = await this._gameService.checkJourneyInfo();
        const wheelGame = await this._gameService.loadWheelInfo();
        return {points, shards, wheelGame, timestamp, referral, tier: getLevel(points), hasRef, capsuleGame, shardGame, journeyGame};
    }

    async getLeaderboard(address: string, points: number): Promise<LeaderboardData> {
        return await this._cacheResponseService.cachePromise(CacheResponseService.PREFIX_KEY_LEADERBOARD, () => this.getLeaderboardInternal(address, points), 600);
    }

    private async getLeaderboardInternal(address: string, points: number) {
        const result: { [key: string]: number } =
            await firstValueFrom(this.getFullUrl('https://taco-space.fra1.cdn.digitaloceanspaces.com/brigade/leaderboard.json'));
        let entriesArray: [string, number][] = Object.entries(result);
        let mySelf = entriesArray.find((item) => {
            return item[0].toLowerCase() === address.toLowerCase();
        });
        if (!mySelf) {
            entriesArray.push([address, points]);
        }
        entriesArray = entriesArray.sort((a, b) => b[1] - a[1]);
        const indexMySelf = entriesArray.findIndex((item) => {
            return item[0].toLowerCase() === address.toLowerCase();
        });

        return {
            position: indexMySelf + 1,
            top: parseFloat((((indexMySelf + 1) / entriesArray.length) * 100).toFixed(2)),
            tot: entriesArray.length,
            suffix: this.ordinal_suffix_of(indexMySelf + 1)
        };
    }

    ordinal_suffix_of(i: number): string {
        let j = i % 10, k = i % 100;
        if (j === 1 && k !== 11) {
            return "st";
        }
        if (j === 2 && k !== 12) {
            return "nd";
        }
        if (j === 3 && k !== 13) {
            return "rd";
        }
        return "th";
    }


}
