
export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const clearAndCache = (elements: any) => {
    let cachedText = [];
    for (let element of elements) {
        cachedText.push(element.textContent);
        element.textContent = "";
    }
    return cachedText;
};

export const animateTypeSync = async (typeables: any, ms: number) => {
    const cachedText = clearAndCache(typeables);

    for (let i = 0; i < typeables.length; i++) {
        for (let character of cachedText[i]) {
            typeables[i].textContent += character;
            await sleep(ms);
        }
    }
};

export function getLevel(level?: number | null): string {
    const levels = [
        { threshold: 3500, label: "Level 10 - Guru" },
        { threshold: 3000, label: "Level 9 - Hero" },
        { threshold: 2500, label: "Level 8 - Master" },
        { threshold: 2000, label: "Level 7 - Veteran" },
        { threshold: 1500, label: "Level 6 - Advanced" },
        { threshold: 1000, label: "Level 5 - Adept" },
        { threshold: 650, label: "Level 4 - Trainee" },
        { threshold: 350, label: "Level 3 - Apprentice" },
        { threshold: 100, label: "Level 2 - Novice" },
        { threshold: 10, label: "Level 1 - Rookie" },
    ];
    if (!level) return "Level 0";

    for (const { threshold, label } of levels) {
        if (level >= threshold) return label;
    }
    return "Level 0";
}
