import {BaseService} from "./base.service";
import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import {getAuth, signInAnonymously} from "firebase/auth";
import {LogService} from "./log.service";
import {LocalStorageService} from "./local-storage.service";


@Injectable({providedIn: 'root'})
export class FirebaseFunctionsService extends BaseService {

    private KEY_FIREBASE_KEY = 'firebase_jwt'

    private authHeaders(): any {
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', 'Bearer ' + this.getJWT());
        return headers;
    }

    getJWT(): string | null {
        return this._localStorageService.getItem(this.KEY_FIREBASE_KEY)
    }

    constructor(
        http: HttpClient,
        log: LogService,
        private _localStorageService: LocalStorageService,
    ) {
        super(http, log);
        this.signInFirebase(true);
    }

    private async signInFirebase(forceRefresh: boolean = false): Promise<string> {
        const auth = getAuth();
        const user = await signInAnonymously(auth);
        const jwt = await user.user.getIdToken(forceRefresh);
        this._localStorageService.setItem(this.KEY_FIREBASE_KEY, jwt)
        return jwt;
    }


    async registerToTopic(token: string, topic: string): Promise<any> {
        await this.signInFirebase();
        const result = await firstValueFrom(this.postFullUrl("https://europe-west1-taco-crypto.cloudfunctions.net/notifications-topic/register",
            {
                token: token,
                topic: topic,
            },
            this.authHeaders()
        ));
        this._log.log('registerToTopic success');
        return result;
    }

}
