export class TitleContainer extends Phaser.GameObjects.Container {

    private readonly harvest: Phaser.GameObjects.Image;
    private readonly comingSoon: Phaser.GameObjects.Image;

    constructor(scene: Phaser.Scene, x: number, y: number) {
        super(scene, x, y);

        this.harvest = scene.add.image(0, 0, 'harvest-label');
        this.comingSoon = scene.add.image(0, 0, 'coming-soon-label');
        this.comingSoon.alpha = 0

        this.add(this.harvest);
        this.add(this.comingSoon);

        scene.add.existing(this);
    }

    public switchLabel() {
        this.comingSoon.alpha = 0;
        this.comingSoon.y = -250
        this.scene.tweens.add({
            targets: this.harvest,
            alpha: 0,
            scale: {from: 1, to: 0.7},
            duration: 500,
            ease: 'Sine.easeOut',
        });
        this.scene.tweens.add({
            targets: this.comingSoon,
            y: 0,
            alpha: 1,
            duration: 500,
            delay: 100,
            ease: 'Sine.easeOut',
        });
    }

}
