<div class="game-modal">
    <img src="/assets/games/ic-close%201.png" height="58"  class="close-button" (click)="closeModal()">
    <div class="cards-modal-content">
        <span class="game-title">CHOOSE A CAPSULE</span>
        <div class="capsule-container">
            <div class="capsule-frame"
                 *ngFor="let c of Array(capsuleNumber); let i = index">

                <div class="reward" *ngIf="prize && i == prizeIndex">
                    <img src="/assets/games/SHARDS.png" height="40">
                    <span>+{{prize}}</span>
                    <span>{{prize == 1 ? 'Shard' : 'Shards'}}</span>
                </div>
                <img src="/assets/games/capsule-loop.gif" class="capsule"
                     [class.disabled]="isPending && i != prizeIndex"
                     [class.selected]="i == prizeIndex"
                     (click)="pickImg(img, i)" #img>
            </div>
        </div>
        <span class="pending" *ngIf="isPending && !prize">Pending transaction...</span>
    </div>
</div>
