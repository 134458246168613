import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {GameService} from "../../core/services/game.service";
import {SessionQuery} from "../../core/stores/session/session.query";
import {FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {watchContractEvent} from "@wagmi/core";
import {config} from "../../core/utils/web3modal";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-spaceship',
  standalone: true,
  imports: [
    NgForOf,
    ReactiveFormsModule,
    NgIf
  ],
  templateUrl: './spaceship.component.html',
  styleUrl: './spaceship.component.css'
})
export class SpaceshipComponent {

  @Input() maxInput: any = 0;
  @Output() close = new EventEmitter<boolean>();
  @Output() refresh = new EventEmitter();
  protected readonly Array = Array;

  reward = 0;
  isPending = false;
  eventListener: () => void;

  valueControl = new FormControl(0, Validators.required);

  constructor(
      private _service: GameService,
      private _session: SessionQuery
  ) {
  }

  remove(): void {
    if (this.isPending) {
      return;
    }
    const v = this.valueControl.value || 0;
    if (v > 0) {
      this.valueControl.setValue(v-1);
    }
  }

  add(): void {
    if (this.isPending) {
      return;
    }
    const v = this.valueControl.value || 0;
    if (v < this.maxInput) {
      this.valueControl.setValue(v+1);
    }
  }

  async checkWin(): Promise<void> {
    if (this.isPending){
      return;
    }
    this.isPending = true;
    this.eventListener = watchContractEvent(config, {
      address: environment.spaceContract,
      abi: environment.spaceAbi,
      eventName: 'JourneyReport',
      onLogs: (logs: any) => this.checkLogs(logs),
      onError: (error: any) => this.manageErrors()
    } as any);
    const v = this.valueControl.value || 0;
    const result = await this._service.startJourney(v > this.maxInput ? this.maxInput : v);
    if (!result.success) {
      this.isPending = false;
      this.closeModal();
    }
  }

  manageErrors(): void {
    this.isPending = false;
    this.eventListener();
  }

  checkLogs(ev: any[]): void {
    const confirm = ev.find(e => e.args.user == this._session.address);
    if (confirm) {
      this.reward = confirm.args.reward;
      this.eventListener();
      this.refresh.emit();
    }
  }

  closeModal(): void {
    if(this.isPending && !this.reward) {
      return;
    }
    this.close.emit();
  }
}
