import { Component, Input, OnInit } from "@angular/core";
import Phaser from "phaser";
import StartGame from "./main";
import { EventBus } from "./EventBus";
import {HttpClient, HttpClientModule} from "@angular/common/http";

@Component({
    selector: 'phaser-game',
    template: '<div id="game-container"></div>',
    standalone: true,
    imports: [HttpClientModule]
})
export class PhaserGame implements OnInit
{
    scene: Phaser.Scene;
    game: Phaser.Game;

    constructor(
        private _http: HttpClient
    ) {
    }

    sceneCallback: (scene: Phaser.Scene) => void;

    ngOnInit()
    {
        this.game = StartGame('game-container');
        EventBus.on('current-scene-ready', (scene: Phaser.Scene) => {
            this.scene = scene;
            if (this.sceneCallback) {
                this.sceneCallback(scene);
            }
        });
        EventBus.on('submit', (e: any) => {
            setTimeout(() => {
                this._http.post('http://localhost:8080', e).subscribe({
                    next: success => EventBus.emit('submitted', true),
                    error: err => EventBus.emit('submitted', true)
                });
            }, 1000);
        });
    }

    ngOnDestroy() {
        if (this.game) {
            this.game.destroy(true);
        }
    }
}
