import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {GameService} from "../../core/services/game.service";
import {watchContractEvent} from "@wagmi/core";
import {config} from "../../core/utils/web3modal";
import {environment} from "../../../environments/environment";
import {SessionQuery} from "../../core/stores/session/session.query";

@Component({
  selector: 'app-cards',
  standalone: true,
  imports: [
    NgForOf,
    NgIf
  ],
  templateUrl: './cards.component.html',
  styleUrl: './cards.component.css'
})
export class CardsComponent {

  @Input() capsuleNumber = 0;
  @Output() close = new EventEmitter<boolean>();
  @Output() refresh = new EventEmitter();
  protected readonly Array = Array;

  isPending = false;
  prize = 0;
  prizeIndex = -1;
  eventListener: () => void;

  constructor(
      private _service: GameService,
      private _session: SessionQuery
  ) {
  }


  async pickImg(img: HTMLImageElement, i: number): Promise<any> {
    if (this.isPending) {
      return;
    }
    this.isPending = true;
    this.prizeIndex = i;
    this.eventListener = watchContractEvent(config, {
      address: environment.capsuleContract,
      abi: environment.capsuleAbi,
      eventName: 'CapsulePicked',
      onLogs: (logs: any) => {
        const confirm = logs.find((e: any) => e.args.user == this._session.address);
        if (confirm) {
          this.eventListener();
          this.prize = confirm.args.reward;
          img.src = '/assets/games/fumo-animation.gif';
          this.refresh.emit();
        }
      },
      onError: (error: any) => {
        this.isPending = false;
        this.prizeIndex = -1;
        this.eventListener();
      }
    } as any);
    const result = await this._service.pickCapsule(i+1);
    if (!result.success) {
      img.src = '/assets/games/capsule.png';
      this.isPending = false;
      this.prizeIndex = -1;
    }
  }
  closeModal(): void {
    this.close.emit();
  }

}
