import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {CardsComponent} from "../../clicker-games/cards/cards.component";
import {HomeData} from "../../core/stores/session/models";
import {CheckInComponent} from "../../clicker-games/check-in/check-in.component";
import Moment from 'moment';
import {interval} from "rxjs";
import {map} from "rxjs/operators";
import {watchContractEvent} from "@wagmi/core";
import {config} from "../../core/utils/web3modal";
import {environment} from "../../../environments/environment";
import {GameService} from "../../core/services/game.service";
import {SessionQuery} from "../../core/stores/session/session.query";
import {SpaceshipComponent} from "../../clicker-games/spaceship/spaceship.component";
import {WheelComponent} from "../../clicker-games/wheel/wheel.component";
import {ShopComponent} from "../../clicker-games/shop/shop.component";

@Component({
  selector: 'app-game-buttons',
  standalone: true,
  imports: [CommonModule, CardsComponent, CheckInComponent, SpaceshipComponent, WheelComponent, ShopComponent],
  templateUrl: './game-buttons.component.html',
  styleUrl: './game-buttons.component.css'
})
export class GameButtonsComponent implements OnInit{

  @Input() set homeData(_gameData: HomeData) {
    if (_gameData) {
      this.gameData = _gameData;
      this.spaceshipPending = this.capsulePending = this.shardsPending = false;
    }
  };
  @Output() refreshData = new EventEmitter<void>();
  @Output() togglePhaser = new EventEmitter<boolean>();

  gameData: HomeData | undefined;
  selectedGame = 0;
  showGame = false;

  private spaceshipPending = false;
  private capsulePending = false;
  private shardsPending = false;
  private wheelPending = false;

  spaceshipEnabled = false;
  capsuleEnabled = false;
  shardsEnabled = false;
  wheelEnabled = false;

  capsuleCooldown = '';
  shardCooldown = '';
  spaceshipCooldown = '';
  wheelCooldown = '';


  ngOnInit() {
    interval(1000)
        .pipe(map(_ => {

          this.capsuleEnabled = Moment().isAfter(this.gameData?.capsuleGame.cooldown) && !this.capsulePending;
          this.shardsEnabled = Moment().isAfter(this.gameData?.shardGame.cooldown) && !this.shardsPending;
          this.spaceshipEnabled =  Moment().isAfter(this.gameData?.journeyGame.cooldown) && !this.spaceshipPending;
          this.wheelEnabled =  Moment().isAfter(this.gameData?.wheelGame.cooldown) && !this.wheelPending;

          const shDiff =  Moment.duration(this.gameData?.shardGame.cooldown.diff(Moment()));
          this.shardCooldown = this.calculateDiff(shDiff);

          const capDiff =  Moment.duration(this.gameData?.capsuleGame.cooldown.diff(Moment()));
          this.capsuleCooldown = this.calculateDiff(capDiff);

          const spaceDiff =  Moment.duration(this.gameData?.journeyGame.cooldown.diff(Moment()));
          this.spaceshipCooldown = this.calculateDiff(spaceDiff);

          const wheelDiff =  Moment.duration(this.gameData?.wheelGame.cooldown.diff(Moment()));
          this.wheelCooldown = this.calculateDiff(wheelDiff);

        }))
        .subscribe();
  }


  openWheelModal(): void {
    if (this.wheelCooldown || this.wheelPending) {
      return;
    }
    this.selectedGame = 1;
    this.showGame = true;
    this.togglePhaser.emit(false);
  }

  openSpaceshipModal(): void {
    if (this.spaceshipCooldown || this.spaceshipPending) {
      return;
    }
    this.showGame = true;
    this.selectedGame = 3;
    this.togglePhaser.emit(false);
  }


  openCheckinModal(): void {
    if (this.shardCooldown || this.shardsPending) {
      return;
    }
    this.showGame = true;
    this.selectedGame = 4;
    this.togglePhaser.emit(false);
  }

  openCardModal(): void {
    if (this.capsuleCooldown || this.capsulePending) {
      return;
    }
    this.showGame = true;
    this.selectedGame = 2;
    this.togglePhaser.emit(false);
  }

  openShopModal(): void {
    this.showGame = true;
    this.selectedGame = 5;
    this.togglePhaser.emit(false);
  }

  closeModal(refresh: any): void {
    this.showGame = false;
    this.togglePhaser.emit(true);
    if (refresh) {
      this.refreshData.emit();
    }
  }

  triggerRefresh(gameN: number) {
    switch (gameN) {
      case 1:
        this.wheelPending = true;
        break;
      case 2:
        this.capsulePending = true;
        break;
      case 3:
        this.spaceshipPending = true;
        break;
      case 4:
        this.shardsPending = true;
        break;
    }
    this.refreshData.emit();
  }



  calculateDiff(difference: Moment.Duration) {
    let variable = '';
    if (difference.hours() > 0) {
      variable += difference.hours() + 'h ' + difference.minutes() + 'm ' + difference.seconds() + 's';
    }
    else if (difference.minutes() > 0) {
      variable += difference.minutes() + 'm ' + difference.seconds() + 's';
    } else if (difference.seconds() > 0) {
      variable += difference.seconds() + 's';
    }
    return variable;
  }

}
