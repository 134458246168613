import {Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ModalService} from "../email-dialog/ModalService";
import {EventBus} from "../../../game/EventBus";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {ConnectorService} from "../../core/services/connector.service";
import {NgClass, NgIf} from "@angular/common";
import {RefCodeService} from "../../core/services/ref-code.service";

@Component({
    selector: 'app-ref-code-dialog',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NgClass,
        NgIf
    ],
    templateUrl: './ref-code-dialog.component.html',
    styleUrl: './ref-code-dialog.component.css'
})
export class RefCodeDialogComponent implements OnInit {

    @Input() id: string = 'ref-code-dialog';
    @Output() onClose = new EventEmitter<boolean>();

    errorText = '';

    private readonly element: any;

    isLoading = false;
    addressControl = new FormControl('');

    constructor(
        private el: ElementRef,
        protected connectorService: ConnectorService,
        private _refCodeService: RefCodeService,
    ) {
        this.element = el.nativeElement;
        if (_refCodeService.refCode) {
            this.addressControl.setValue(_refCodeService.refCode);
        }
    }

    ngOnInit() {
    }

    async onConfirm() {
        this.errorText = '';
        const value = this.addressControl.value;
        if (!value) {
            return;
        }
        this.isLoading = true
        const result = await this.connectorService.linkReferralCode(value)
        if (result.success) {
            this._refCodeService.removeRefCode();
            this.close(true)
        } else {
            this.errorText = result.error ?? '';
        }
        this.isLoading = false
    }

    close(success: boolean) {
        this.onClose.emit(success)
    }

}
