import Phaser from "phaser";
import {EventBus} from "../../EventBus";
import {StatusGame} from "../../components/models";
import {MonitorTime} from "./MonitorTime";

export class TerrainContainer extends Phaser.GameObjects.Container {

    public readonly box: Phaser.GameObjects.Image;
    public boxGreen: Phaser.GameObjects.Image;
    private readonly fruit: Phaser.GameObjects.Sprite;
    private readonly topLayerImage: Phaser.GameObjects.Image;
    private blobImage: Phaser.GameObjects.Image;
    public readonly chat: Phaser.GameObjects.Image;
    public chatText: Phaser.GameObjects.Text;
    public monitor: MonitorTime;
    private arrowImage: Phaser.GameObjects.Image | undefined;
    private arrowTween: Phaser.Tweens.Tween;

    private readonly titleHarvest: Phaser.GameObjects.Image

    private fruitSpriteLabel: string;
    private dots: Phaser.GameObjects.GameObject[] = [];

    private readonly DEFAULT_FRUIT_Y = 25;

    private isConnected = false

    private readonly callbackBadge: () => void;

    getBadgeImageY(): number {
        return this.box.y - 250
    }

    getBadgeImageX(): number {
        return 0;
    }

    private readonly sprites: string[] = ['carroll-sheet', 'stoner-sheet', 'peene-sheet', 'korn-sheet', 'ceppi-sheet', 'bohtato-sheet', 'carroll-sheet', 'tundis-sheet']

    constructor(scene: Phaser.Scene, x: number, y: number, box: string, fruitSprite: string, fruitOffset = 0, badgeImage: string, callbackBadge: () => void) {
        super(scene, x, y);
        this.titleHarvest = this.scene.add.image(0, -450, 'title').setScale(0.5, 0.5);

        this.box = scene.add.image(0, 0, box);
        this.boxGreen = this.scene.add.image(0, 0, 'carrot-terrain-green');
        this.boxGreen.setVisible(false)
        this.fruit = scene.add.sprite(0, this.DEFAULT_FRUIT_Y, this.fruitSpriteLabel);
        this.topLayerImage = scene.add.image(0, 0, "carrot-terrain");
        this.chat = this.scene.add.image(0, this.getBadgeImageY(), "chat").setOrigin(0, 1)
        this.chat.setVisible(false)

        this.monitor = new MonitorTime(scene, 250, -20);
        this.monitor.setVisible(false);

        this.add([this.titleHarvest, this.box, this.boxGreen, this.fruit, this.topLayerImage, this.chat, this.monitor]);
        scene.add.existing(this);
        this.loadSprite();
        this.callbackBadge = callbackBadge;
        //Phaser.Display.Align.In.Center(this.fruit, this.box, 0, fruitOffset);

        EventBus.on('isConnected', (isConnected: boolean) => {
            this.isConnected = isConnected;
        });
        EventBus.on('disconnected', (scene: Phaser.Scene) => {
            this.setStatus(StatusGame.DISCONNECTED, true)
        });
        EventBus.on('timer-finished', (scene: Phaser.Scene) => {
            this.setStatus(StatusGame.CONNECTED, true)
        });
    }

    animFruit: Phaser.Animations.Animation | undefined;

    private loadSprite() {
        this.fruitSpriteLabel = Phaser.Math.RND.pick(this.sprites);
        const key = 'fruit-idle';
        try {
            this.animFruit?.destroy()
            const animFruit = this.scene.anims.create({
                key: key,
                frames: this.fruitSpriteLabel,
                frameRate: 3,
                repeat: -1
            });
            if (animFruit !== false) {
                this.animFruit = animFruit;
            }
            this.fruit.setTexture(this.fruitSpriteLabel);
            this.fruit.anims.play(key, true);
        } catch (e) {
            console.error(e)
        }
    }

    public setPointerEvent(): void {
        this.topLayerImage.setInteractive({cursor: 'pointer'});
        this.topLayerImage.removeAllListeners('pointerup');
        this.topLayerImage.removeAllListeners('pointerdown');
        this.topLayerImage.on('pointerdown', this.onContainerClick, this);
        this.arrowImage?.destroy()
        this.arrowImage = this.scene.add.image(0, 0, 'arrow');
        Phaser.Display.Align.In.TopCenter(this.arrowImage, this.box, 0, 100);
        this.add(this.arrowImage)
        this.arrowTween?.destroy()
        this.arrowTween = this.scene.tweens.add({
            targets: this.arrowImage,
            y: '-=40',
            duration: 600,
            ease: Phaser.Math.Easing.Sine.InOut,
            yoyo: true,
            repeat: -1
        });
    }

    public disablePointerEvent(): void {
        this.scene.input.setDefaultCursor('default');
        this.topLayerImage.disableInteractive();
        this.arrowTween?.stop()
        this.arrowImage?.destroy()
    }

    private onContainerClick(): void {
        if (!this.isConnected) {
            EventBus.emit('connect-wallet');
            return
        }
        this.setStatus(StatusGame.CHECK_WALLET, true, () => {
            EventBus.emit('sign-harvest');
        })
    }

    setTimestamp(timestamp: number) {
        this.monitor.startCountdown(timestamp)
    }

    currentStatus: StatusGame;
    setStatus(statusGame: StatusGame, anim: boolean = false, after?: () => void) {
        switch (statusGame) {
            case StatusGame.DISCONNECTED:
                this.titleHarvest?.setVisible(true)
                this.resetFruit(false)
                this.hideChat();
                this.monitor.setVisible(false)
                this.box.setVisible(true)
                this.boxGreen.setVisible(false)
                this.disablePointerEvent();
                this.blobImage?.destroy()
                break;
            case StatusGame.CONNECTED:
                this.titleHarvest?.setVisible(true)
                this.resetFruit(anim, () => {
                    this.setPointerEvent()
                })
                this.hideChat();
                this.monitor.setVisible(false)
                this.box.setVisible(true)
                this.boxGreen.setVisible(false)
                this.blobImage?.destroy()
                break;
            case StatusGame.CHECK_WALLET:
                this.titleHarvest?.setVisible(false)
                this.scene.tweens.add({
                    targets: this.fruit,
                    y: this.getBadgeImageY(),
                    duration: (anim ? 1000 : 0),
                    ease: 'Power2',
                    onComplete: () => {
                        this.showChat("Check your wallet", after)
                    }
                });
                this.monitor.setVisible(false)
                this.box.setVisible(true)
                this.boxGreen.setVisible(false)
                this.disablePointerEvent();
                this.blobImage?.destroy()
                break;
            case StatusGame.WAITING_TX:
                this.titleHarvest?.setVisible(false)
                this.showChat("Processing\ntransaction")
                this.monitor.setVisible(false)
                this.box.setVisible(true)
                this.boxGreen.setVisible(false)
                this.disablePointerEvent();
                this.blobImage?.destroy()
                break;
            case StatusGame.CLAIMED:
                this.titleHarvest?.setVisible(false)
                if (this.currentStatus === StatusGame.CLAIMED) {
                    return;
                }
                this.hideChat();
                this.box.setVisible(true)
                this.disablePointerEvent();

                if (anim) {
                    this.scene.tweens.add({
                        targets: this.fruit,
                        y: '-=50',
                        duration: 500,
                        ease: Phaser.Math.Easing.Sine.In,
                        yoyo: true,
                        repeat: 0,
                        onComplete: () => {
                            this.boxGreen.setVisible(true)
                            this.showSmoke()
                            this.scene.time.delayedCall(1500, () => {
                                this.showMonitor()
                                this.showBlob();
                                this.loadSprite();
                                if (after) {
                                    after()
                                }
                            }, [], this);
                        }
                    });
                } else {
                    this.hideTargetImage();
                    this.boxGreen.setVisible(true)
                    this.showMonitor();
                    this.showBlob();
                }
                break;
        }
        this.currentStatus = statusGame;
    }

    private showBlob() {
        this.blobImage?.destroy()
        this.blobImage = this.scene.add.image(0, 0, "blob-small");
        this.add(this.blobImage)

        this.scene.tweens.add({
            targets: this.blobImage,
            y: {from: 30, to: 0},
            alpha: {from: 0, to: 1},
            duration: 2000,
            ease: Phaser.Math.Easing.Sine.In,
            onComplete: () => {
                this.scene.tweens.add({
                    targets: this.blobImage,
                    y: '-=20',
                    duration: 1000,
                    ease: Phaser.Math.Easing.Sine.InOut,
                    yoyo: true,
                    repeat: -1
                });
            }
        });
    }

    private resetFruit(anim: boolean, onComplete?: Phaser.Types.Tweens.TweenOnCompleteCallback) {
        this.fruit.setVisible(true)
        this.fruit.alpha = 1
        if (!anim) {
            this.fruit.y = this.DEFAULT_FRUIT_Y
        } else {
            this.arrowTween?.destroy()
            this.arrowTween = this.scene.tweens.add({
                targets: this.fruit,
                y: this.DEFAULT_FRUIT_Y,
                duration: (anim ? 1000 : 0),
                ease: Phaser.Math.Easing.Sine.In,
                onComplete: onComplete
            });
        }
    }

    private showChat(textValue: string, after?: () => void) {
        if (this.chat.visible) {
            this.chat.alpha = 1;
            this.chat.scale = 1;
            this.showChatText(textValue)
            if (after) {
                after()
            }
            return
        }
        this.chat.alpha = 0;
        this.chat.scale = 0
        this.chat.setVisible(true);
        this.scene.tweens.add({
            targets: this.chat,
            alpha: {from: 0, to: 1},
            scale: {from: 0, to: 1},
            ease: 'Bounce.easeOut',
            delay: 0,
            duration: 800,
            onComplete: () => {
                this.showChatText(textValue)
                if (after) {
                    after()
                }
            }
        });
    }

    private hideChat() {
        this.chat.setVisible(false)
        this.chatText?.destroy(false)
        this.clearDots()
    }

    showChatText(textValue: string) {
        this.chatText?.destroy(false)
        const maxWidth = 350; // La larghezza massima che vuoi dare al tuo testo

        // Creazione del testo
        const text = this.scene.add.text((this.chat.x + this.chat.width / 2), this.chat.y + 80 -230, textValue, {
            fontFamily: 'Jersey20',
            fontSize: '36px',
            color: '#000000',
            align: 'center',
            wordWrap: {width: maxWidth}
        }).setOrigin(0.5, 0.5)

        // Verifica se il testo è fuori dai limiti
        if (text.width > maxWidth) {
            text.setWordWrapWidth(maxWidth);
        }
        this.chatText = text;
        this.add(this.chatText)
        this.addDots();
    }

    clearDots() {
        this.dots?.forEach(dot => {
            dot.destroy(false)
        })
        this.dots = [];
    }

    addDots() {
        this.clearDots();
        const dotCount = 3;
        const pulseDuration = 500;
        for (let i = 0; i < dotCount; i++) {
            let dot = this.scene.add.rectangle(
                (this.chat.x + this.chat.width / 2) + (i - 1) * 50,
                this.chatText ? this.chatText.y + this.chatText.height : this.chatText + 140,
                8,
                8,
                0x000000
            )
            this.dots.push(dot);
            this.add(dot);

            // Create the pulsing tween
            this.scene.tweens.add({
                targets: dot,
                scale: 1.5,
                ease: 'Sine.easeInOut',
                duration: pulseDuration,
                repeat: -1,
                yoyo: true,
                delay: i * (pulseDuration / dotCount)
            });
        }
    }

    private showSmoke() {
        const smokePositions = [
            {x: this.getBadgeImageX() - 30, y: this.getBadgeImageY() + 80},
            {x: this.getBadgeImageX() + 50, y: this.getBadgeImageY() + 80},
            {x: this.getBadgeImageX(), y: this.getBadgeImageY()},
            {x: this.getBadgeImageX() + 50, y: this.getBadgeImageY() + -50},
            {x: this.getBadgeImageX() + -40, y: this.getBadgeImageY() + -80},
            {x: this.getBadgeImageX() + -50, y: this.getBadgeImageY() + 40},
        ].sort(() => Math.random() - 0.5);

        smokePositions.forEach((pos, index) => {
            const bubble = this.scene.add.image(pos.x, pos.y, 'bubble');
            this.add(bubble)
            bubble.setScale(Phaser.Math.FloatBetween(1.1, 1.5));
            bubble.setAlpha(0);
            bubble.setScale(0.7);


            this.scene.tweens.add({
                targets: bubble,
                alpha: 1,
                scale: 1,
                duration: 300,
                delay: index * 10,
                onComplete: () => {
                    if (index === 0) {
                        this.hideTargetImage();
                    }
                    this.scene.tweens.add({
                        targets: bubble,
                        alpha: 0,
                        scale: 0,
                        y: '-=40',
                        duration: 1000 - (index * 100),
                        delay: 150,
                        onComplete: () => {
                            bubble.destroy();
                        }
                    });
                }
            });
        });
    }

    private showMonitor() {
        this.monitor.setVisible(true);
        this.scene.tweens.add({
            targets: this.monitor,
            y: {from: -200, to: 0},
            alpha: {from: 0, to: 1},
            duration: 600,
            ease: Phaser.Math.Easing.Sine.In,
            onComplete: () => {

            }
        });
    }

    private hideTargetImage() {
        this.scene.tweens.add({
            targets: this.fruit,
            alpha: 0,
            duration: 500,
            onComplete: () => {
                this.fruit.setVisible(false);
            }
        });
    }

}
