import {Component} from '@angular/core';
import {ConnectorService} from "../../core/services/connector.service";
import {Observable} from "rxjs";
import {SessionQuery} from "../../core/stores/session/session.query";
import {AsyncPipe} from "@angular/common";

@Component({
    selector: 'app-wallet-button',
    standalone: true,
    imports: [
        AsyncPipe
    ],
    templateUrl: './wallet-button.component.html',
    styleUrl: './wallet-button.component.css'
})
export class WalletButtonComponent {

    address$ = this._session.address$;
    isConnecting$: Observable<boolean> = this.connectorService.isConnecting$;

    constructor(
        private _session: SessionQuery,
        protected connectorService: ConnectorService,
    ) {
    }

    connect() {
        this.connectorService.connect()
    }

}
