import {Component, OnInit} from '@angular/core';
import {Page1Component} from "../page-1/page-1.component";

@Component({
    selector: 'app-page-3',
    standalone: true,
    imports: [],
    templateUrl: './page-3.component.html',
    styleUrls: ['../page-1/page-1.component.css', './page-3.component.css']

})
export class Page3Component extends Page1Component implements OnInit {

    override originalText = "Improve your individual and global ranking.";

}
