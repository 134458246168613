import {Query} from '@datorama/akita';
import {SessionStore} from './session.store';
import {Injectable} from "@angular/core";
import {map} from "rxjs/operators";
import {InfoStorage} from "./models";

@Injectable({providedIn: 'root'})
export class SessionQuery extends Query<InfoStorage> {

  isLogged$ = this.select('address').pipe(map(s => !!s));
  address$ = this.select('address');

  get address(): string | null {
    return this.getValue().address;
  }

  get isLogged(): boolean {
    return !!this.address;
  }

  constructor(protected _sessionStore: SessionStore) {
    super(_sessionStore);
  }

}
