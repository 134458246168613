<div class="container-modal" (click)="$event.stopImmediatePropagation(); close(false)" >
    <div class="modal modal-open flex flex-col items-center" (click)="$event.stopImmediatePropagation()">
        <div class="stroke top"></div>
        <div class="stroke left"></div>
        <div class="stroke right"></div>
        <div class="stroke bottom"></div>

        <div class="close pointer" (click)="close(false)">
            <img src="/assets/images/button-close.png" class="full-size">
        </div>

        <span class="description centerText">
            Insert other’s ref code to gain a <b>GEM POWER</b> boost
        </span>

        <div class="wrap-input flex flex-col justify-center" style="margin-top: 40px">
            <input type="text" class="form-control" placeholder="Insert ref code" autocomplete="off" [formControl]="addressControl">
        </div>
        <div class="container-error-label">
            <span class="error-label" *ngIf="errorText">{{errorText}}</span>
        </div>
        <button class="button primary" [ngClass]="{'loading': isLoading}" (click)="onConfirm()"></button>
    </div>
</div>

