import Phaser from "phaser";
import {EventBus} from "../../EventBus";

export class MonitorTime extends Phaser.GameObjects.Container {

    public readonly monitor: Phaser.GameObjects.Image;

    private countdownText: any;
    private countdownTime = 10;
    private countdownEvent: Phaser.Time.TimerEvent;
    private targetTimestamp: number = new Date().getTime() + (60 * 1000);

    constructor(scene: Phaser.Scene, x: number, y: number) {
        super(scene, x, y);

        this.countdownText = this.scene.add.text(-60, -40, "sdf", {
            fontFamily: 'Jersey20',
            fontSize: '22px',
            color: '#93FFEC',
        });
        this.countdownText.setOrigin(0.5, 0.5)
        this.monitor = scene.add.image(0, 0, "stand-time")

        this.add([this.monitor, this.countdownText]);
        scene.add.existing(this);
        this.startCountdown(0)
    }

    clearTimer() {
        this.countdownEvent?.remove(false);
        this.countdownEvent?.destroy()
    }

    startCountdown(timestamp: number) {
        this.clearTimer();

        this.targetTimestamp = timestamp;
        this.countdownTime = Math.floor((this.targetTimestamp - (new Date().getTime() / 1000)));
        this.countdownText.setOrigin(0, 0.5)
        this.countdownText.setText(this.convertSeconds(this.countdownTime));

        this.countdownEvent = this.scene.time.addEvent({
            delay: 1000,
            callback: () => {
                this.updateCountdown();
            },
            callbackScope: this.scene,
            loop: true
        });
    }

    private convertSeconds(seconds: number): string {
        if (seconds <= 0) {
            return '00h 00m 00s'
        }
        const h = Math.floor(seconds / 3600);
        const m = Math.floor(seconds % 3600 / 60);
        const s = (seconds % 3600) % 60;
        return (h > 0 ? h + 'h ' + (m < 10 ? '0' : '') : '') + m + 'm ' + (s < 10 ? '0' : '') + s + 's';
    }

    updateCountdown() {
        this.countdownTime = Math.floor((this.targetTimestamp - (new Date().getTime() / 1000)));

        if (this.countdownTime <= 0) {
            this.countdownEvent.remove(false); // Ferma il timer senza triggerare il callback
            this.countdownText.setText('Go!');
            EventBus.emit('timer-finished')
        } else {
            this.countdownText?.setText(this.convertSeconds(this.countdownTime));
        }
    }

    override setVisible(value: boolean): this {
        if (!value) {
            this.clearTimer();
        }
        return super.setVisible(value);
    }

}
