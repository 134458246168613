import { Component } from '@angular/core';
import {NgClass, NgIf} from "@angular/common";
import {Observable} from "rxjs";
import {SessionQuery} from "../../core/stores/session/session.query";
import {ConnectorService} from "../../core/services/connector.service";
import {getNetworkId} from "../../core/utils/web3modal";
import {ReactiveFormsModule} from "@angular/forms";

@Component({
  selector: 'app-wrong-network',
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    ReactiveFormsModule
  ],
  templateUrl: './wrong-network.component.html',
  styleUrl: './wrong-network.component.css'
})
export class WrongNetworkComponent {


  readonly chain = getNetworkId()

  constructor(
      private _session: SessionQuery,
      protected connectorService: ConnectorService,
  ) {
  }

  switchNetwork() {
    this.connectorService.switchNetwork()
  }

  logout() {
    this.connectorService.logout()
  }

}
