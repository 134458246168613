import {Component, OnInit} from '@angular/core';
import {sleep} from "../../../../core/utils/functions";

@Component({
    selector: 'app-page-1',
    standalone: true,
    imports: [],
    templateUrl: './page-1.component.html',
    styleUrl: './page-1.component.css'
})
export class Page1Component implements OnInit {

    originalText = "Rebuilding reality is no joke, dude. Lucky enough, you’re early.";
    text = "";

    ngOnInit() {
        this.setText();
    }

    async setText() {
        await sleep(200);
        for (let i = 0; i < this.originalText.length; i++) {
            this.text += this.originalText[i];
            await sleep(40);
        }
    }

}
