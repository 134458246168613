import {persistState} from "@datorama/akita";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";
import {bootstrapApplication} from "@angular/platform-browser";
import {AppComponent} from "./app/app.component";
import {appConfig} from "./app/app.config";

import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {firebaseConfig} from "./firebase/firebase";
import {getMessaging} from "firebase/messaging";

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
try {
    const messaging = getMessaging(app);
} catch (e) {}

const storage = persistState();

const providers = [{provide: 'session', useValue: storage}];

platformBrowserDynamic(providers)

bootstrapApplication(AppComponent, appConfig)
    .catch((err) => console.error(err));
