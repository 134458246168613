import {Component, ElementRef, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalService} from "./ModalService";
import {EventBus} from "../../../game/EventBus";

@Component({
    selector: 'app-email-dialog',
    standalone: true,
    imports: [],
    templateUrl: './email-dialog.component.html',
    styleUrl: './email-dialog.component.css'
})
export class EmailDialogComponent implements OnInit, OnDestroy {

    @Input() id: string = 'email-dialog';

    isOpen = false;
    private readonly element: any;

    constructor(protected modalService: ModalService, private el: ElementRef) {
        this.element = el.nativeElement;
    }

    ngOnInit() {
        this.modalService.add(this);
        document.body.appendChild(this.element);
    }

    @HostListener('window:completed-send-email', ['$event'])
    completedSendEmail(event: any) {
        this.close();
        EventBus.emit('show-thank-you-page');
    }

    ngOnDestroy() {
        this.modalService.remove(this);
        this.element.remove();
    }

    open() {
        this.element.style.display = 'block';
        this.isOpen = true;
    }

    close() {
        this.element.style.display = 'none';
        this.isOpen = false;
    }
}
