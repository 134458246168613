<div class="full-width flex flex-col items-center">
    <div class="shop-modal">
        <img src="/assets/games/ic-close%201.png" height="58"  class="close-button" (click)="closeModal()">

        <div class="tenda"></div>
        <div class="tenda-shadow"></div>
        <div class="shop-divider hide-on-mobile" *ngIf="list.length <= 3"></div>
        <div class="cards-modal-content no-scrollbar" *ngIf="!isTransacting">
            <div *ngFor="let item of list" class="card flex flex-col">
                <div class="card-image">
                    <img class="image" [src]="item.image" />
                    <span class="card-title">{{item.name}}</span>
                    <span class="purchased-title" *ngIf="item.maxClaimsPerUser > 0">Max claim {{item.maxClaimsPerUser}}</span>
                </div>
                <div (click)="clickProduct(item)" class="button-container shrink-on-hover">
                    <div class="item-button green flex flex-row" *ngIf="item.price > 0; else freeButton">
                        <span>BUY {{item.price}}</span>
                        <img src="assets/images/shard-big.png" width="14" height="24" style="margin-left: 6px">
                    </div>
                    <ng-template #freeButton>
                        <div class="item-button blue">FREE</div>
                    </ng-template>
                </div>
            </div>
        </div>
        <div style="position: absolute; left: 0; top: 180px" class="full-width flex flex-row items-center" *ngIf="isLoading || isTransacting">
            <app-loader-brigade></app-loader-brigade>
            <div class="check-your-wallet centerText full-width" *ngIf="isTransacting">{{!isVerifying ? 'Check your wallet...' : 'Verifying transaction...'}}</div>
        </div>
    </div>

</div>
