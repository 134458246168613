import {Component, OnInit} from '@angular/core';
import {Page1Component} from "../page-1/page-1.component";

@Component({
    selector: 'app-page-2',
    standalone: true,
    imports: [],
    templateUrl: './page-2.component.html',
    styleUrls: ['../page-1/page-1.component.css', './page-2.component.css']
})
export class Page2Component extends Page1Component implements OnInit {

    override originalText = "Harvest & collect gem power. Use ref code to get more!";

}
