import {Injectable} from "@angular/core";
import {NgxToastService, NgxToastType} from "@angular-magic/ngx-toast";

@Injectable({providedIn: 'root'})
export class ToastService {

  constructor(
      private ngxToastService: NgxToastService
  ) {
    ngxToastService.setCustomDelays({[NgxToastType.error]: 5000});
  }

  open(message: string, toastType: ToastType = ToastType.SUCCESS) {
    switch (toastType) {
        case ToastType.SUCCESS:
            this.ngxToastService.success({title: 'Success', messages: [message ?? '']});
            break;
        case ToastType.WAITING:
        case ToastType.INFO:
            this.ngxToastService.info({title: 'Info', messages: [message ?? '']});
            break;
        case ToastType.ERROR:
            if (message.startsWith('The contract function') && message.includes('reverted with the following reason')) {
                //The contract function "claimProduct" reverted with the following reason: Asset: Type not enabled
                const functionName = message.split('The contract function ')[1].split(' ')[0].replace(/"/g, '');
                this.ngxToastService.error({title: 'Error ' + functionName, messages: [message.split('reverted with the following reason:')[1].trim()]});
            } else {
                this.ngxToastService.error({title: 'Error', messages: [message ?? '']});
            }
            break;
    }
  }

  private getToastType(toastType: ToastType): any {
    switch (toastType) {
      case ToastType.SUCCESS:
        return 'success';
      case ToastType.WAITING:
      case ToastType.INFO:
        return 'info';
      case ToastType.ERROR:
        return 'danger';
    }
  }

}

export enum ToastType {
  SUCCESS,
  INFO,
  WAITING,
  ERROR,
}
