declare const WebFont: any;
export class ProgressContainer extends Phaser.GameObjects.Container {

    private readonly progress: Phaser.GameObjects.Image;
    private progressSelectedLeft: Phaser.GameObjects.Image;
    private progressSelectedRight: Phaser.GameObjects.Image;
    private pointLabelText: Phaser.GameObjects.Text;
    private scoreText: Phaser.GameObjects.Text;
    private plus500: Phaser.GameObjects.Image;

    private readonly valueStep = 500;
    private points = 0;

    private readonly callbackFirstPoints: () => void;
    private readonly callbackComplete: () => void;

    preload(): void {
    }

    constructor(scene: Phaser.Scene, x: number, y: number, callbackFirstPoints: () => void, callbackComplete: () => void) {
        super(scene, x, y);

        this.plus500 = scene.add.image(0, 0, 'plus-500').setScale(0.5, 0.5);
        this.progress = scene.add.image(0, 0, "progress").setOrigin(0, 0);
        this.callbackFirstPoints = callbackFirstPoints;
        this.callbackComplete = callbackComplete;
        this.add(this.plus500);
        this.add(this.progress);

        WebFont.load({
            custom: {
                families: ['Jersey20']
            },
            active: () => {
                this.resetPlus500();
                const textStyle: Phaser.Types.GameObjects.Text.TextStyle = {fontFamily: 'Jersey20', fontSize: 26, color: '#FFFFFF', fontStyle: '500', resolution: 100 };
                this.pointLabelText = scene.add.text(0, 0, 'Points:', textStyle).setOrigin(0, 0.5);
                this.add(this.pointLabelText);
                this.scoreText = scene.add.text(0, 0, '0', {...textStyle }).setOrigin(1, 0.5);
                Phaser.Display.Align.In.BottomLeft( this.pointLabelText, this.progress, -35, -22);
                Phaser.Display.Align.In.BottomRight( this.scoreText, this.progress, -55, -22);
                this.add(this.scoreText);
                scene.add.existing(this);
            }

        });
    }

    public addPoints() {
        if (this.points === 0) {
            this.points += this.valueStep;
            this.progressSelectedLeft = this.scene.add.image(0, 0, "progress-selected-left").setScale(0.5, 0.5).setOrigin(0, 0)
            this.add(this.progressSelectedLeft);
            this.showProgressTab(this.progressSelectedLeft);
            this.showPlus500Image(true);
            this.scoreText.text = this.valueStep.toString()
        } else {
            this.points += this.valueStep;
            this.progressSelectedRight = this.scene.add.image(this.progress.width / 2, 0, "progress-selected-right").setScale(0.5, 0.5).setOrigin(0, 0);
            this.add(this.progressSelectedRight)
            this.showProgressTab(this.progressSelectedRight)
            this.showPlus500Image(false);
            this.scoreText.text = (this.valueStep * 2).toString()
        }
    }

    private resetPlus500() {
        this.plus500.setY(0);
        this.plus500.alpha = 0;
    }

    private showPlus500Image(call: boolean) {
        this.plus500.setX(this.progress.width / 2);
        this.scene.tweens.add({
            targets: this.plus500,
            y: -50,
            alpha: 1,
            duration: 500,
            ease: 'Sine.easeOut',
            onComplete: () => {
                this.scene.tweens.add({
                    targets: this.plus500,
                    alpha: 0,
                    y: '+=40',
                    delay: 1000,
                    duration: 300,
                    onComplete: () => {
                        if (this.points <= this.valueStep) {
                            this.callbackFirstPoints();
                        } else {
                            this.callbackComplete();
                        }
                    }
                });
            }
        });
    }

    private showProgressTab(image: Phaser.GameObjects.Image) {
        image.setAlpha(0);
        const flickerTimes = [100, 200, 300];
        const createFlicker = (time: number, visible: boolean) => {
            this.scene.time.addEvent({
                delay: time,
                callback: () => {
                    image.setAlpha(visible ? 1 : 0);
                }
            });
        };
        flickerTimes.forEach((time, index) => {
            createFlicker(time, index % 2 === 0);
        });
        this.scene.time.addEvent({
            delay: flickerTimes[flickerTimes.length - 1] + 100,
            callback: () => {
                image.setAlpha(1);
            }
        });
    }

}
