import '@web3modal/polyfills';
import { createConfig } from '@wagmi/core';
import { coinbaseWallet, walletConnect, injected } from '@wagmi/connectors';
import { authConnector } from '../connectors/AuthConnector.js';
import { getTransport } from './helpers.js';
export function defaultWagmiConfig({
  projectId,
  chains,
  metadata,
  enableCoinbase,
  enableEmail,
  enableInjected,
  auth = {
    showWallets: true
  },
  enableWalletConnect,
  enableEIP6963,
  ...wagmiConfig
}) {
  const connectors = [];
  const transportsArr = chains.map(chain => [chain.id, getTransport({
    chainId: chain.id,
    projectId
  })]);
  const transports = Object.fromEntries(transportsArr);
  if (enableWalletConnect !== false) {
    connectors.push(walletConnect({
      projectId,
      metadata,
      showQrModal: false
    }));
  }
  if (enableInjected !== false) {
    connectors.push(injected({
      shimDisconnect: true
    }));
  }
  if (enableCoinbase !== false) {
    connectors.push(coinbaseWallet({
      version: '4',
      appName: metadata?.name ?? 'Unknown',
      appLogoUrl: metadata?.icons[0] ?? 'Unknown',
      preference: wagmiConfig.coinbasePreference || 'all'
    }));
  }
  if (enableEmail || auth?.socials) {
    connectors.push(authConnector({
      chains: [...chains],
      options: {
        projectId
      },
      socials: auth?.socials,
      email: enableEmail,
      showWallets: auth.showWallets
    }));
  }
  return createConfig({
    chains,
    multiInjectedProviderDiscovery: enableEIP6963 !== false,
    transports,
    ...wagmiConfig,
    connectors
  });
}
