import Phaser from "phaser";

export class ConfettiContainer extends Phaser.GameObjects.Container {

    private readonly offsetStartY = 50;

    private readonly confettiCount: number;
    private readonly startX: number;
    private readonly endX: number;

    constructor(scene: Phaser.Scene, x: number, y: number, confettiCount: number) {
        super(scene, x, y);

        /*const maxWidth = Math.min(scene.scale.width, 600);
        this.startX = (scene.scale.width - maxWidth) / 2;
        this.endX = scene.scale.width - this.startX;*/
        this.startX = 0;
        this.endX = scene.scale.width;

        this.confettiCount = confettiCount;

        scene.add.existing(this);
    }

    start() {
        for (let i = 0; i < this.confettiCount; i++) {
            const x = Phaser.Math.Between(this.startX, this.endX);
            const valueConf = Math.floor(Math.random() * 5) + 1;
            const image = this.scene.add.image(x, -this.offsetStartY, 'confetti-' + valueConf).setScale(0.5)
            image.rotation = Phaser.Math.FloatBetween(0, Math.PI * 2);
            this.add(image);
            this.startAnimation(image);
        }
    }

    private startAnimation(image: Phaser.GameObjects.Image) {
        const randomXOffset = Phaser.Math.Between(-200, 200);

        this.scene.tweens.add({
            targets: image,
            y: this.scene.scale.height + this.offsetStartY,
            x: image.x + randomXOffset,
            delay: Phaser.Math.Between(0, 500),
            duration: Phaser.Math.Between(1500, 2500),
            ease: Phaser.Math.Easing.Linear,
            onComplete: () => {
                image.destroy();
            }
        });
        this.scene.tweens.add({
            targets: image,
            loop: -1,
            scaleX: -0.5,
            delay: Phaser.Math.Between(0, 100),
            duration: Phaser.Math.Between(150, 250),
            ease: 'Linear'
        });
    }

}
