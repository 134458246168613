<div class="container-modal" id="modal-tutorial">
    <div class="wrap-container-frame">
        <div class="container-frame">
            <div class="inner-resized">
                <div class="container-router">
                    <app-page-1 *ngIf="index == 0"></app-page-1>
                    <app-page-2 *ngIf="index == 1"></app-page-2>
                    <app-page-3 *ngIf="index == 2"></app-page-3>
                    <app-page-4 *ngIf="index == 3"></app-page-4>
                    <app-page-5 *ngIf="index == 4"></app-page-5>
                </div>
                <div class="frame"></div>
                <div class="close pointer" (click)="close()">
                    <img src="/assets/images/button-close.png" class="full-size">
                </div>
            </div>
            <div class="button-continue" (click)="onContinue()" [ngClass]="{'right' : index == 4}"></div>
            <a href="https://medium.com/@taco.wax/brigade-mini-game-taiko-60147b4d6873" target="_blank">
                <div class="button-more" (click)="onMoreInfo.emit()" *ngIf="index == 4"></div>
            </a>
        </div>
    </div>
</div>
