import {Injectable} from "@angular/core";
import {EmailDialogComponent} from "./email-dialog.component";
import {BehaviorSubject} from "rxjs";

@Injectable({providedIn: 'root'})
export class ModalService {

    private readonly _modals$ = new BehaviorSubject<any[]>([]);
    public modal$ = this._modals$.asObservable()

    private modals: any[] = [];

    add(modal: any) {
        if (!modal.id || this.modals.find(x => x.id === modal.id)) {
            throw new Error('modal must have a unique id attribute');
        }
        this.modals.push(modal);
    }

    remove(modal: any) {
        this.modals = this.modals.filter(x => x === modal);
    }

    open(id: string) {
        const modal = this.modals.find(x => x.id === id);
        if (!modal) {
            throw new Error(`modal '${id}' not found`);
        }
        modal?.open();
    }

}
