import {Store, StoreConfig} from '@datorama/akita';
import {Injectable} from "@angular/core";
import {InfoStorage} from "./models";

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'session'})
export class SessionStore extends Store<InfoStorage> {

    constructor() {
        super({
            address: null,
            isTutorialWatched: false,
        });
    }

    logout(): void {
        this.update({address: null});
    }

}
