import {Scene} from 'phaser';
import {TitleContainer} from "./components/TitleContainer";
import {TerrainContainer} from "./components/TerrainContainer";
import {ProgressContainer} from "./components/ProgressContainer";
import {SocialLinksContainer} from "./components/SocialLinksContainer";
import {EventBus} from "../EventBus";

export class BrigadeHome extends Scene {

    carrotTerrain: TerrainContainer;
    cornTerrain: TerrainContainer;
    progressContainer: ProgressContainer;
    titleContainer: TitleContainer;
    socialLinksContainer: SocialLinksContainer;

    private ground: Phaser.GameObjects.Image;

    private circleBlue: Phaser.GameObjects.Image;
    private circleViolet: Phaser.GameObjects.Image;

    private centeredGroup: Phaser.GameObjects.Group;
    private scaleContainer: Phaser.GameObjects.Container;

    constructor() {
        super('BrigadeHome');
    }

    create() {
        this.circleBlue = this.add.image(0, 0, 'circle-blue').setScale(0.5);
        this.circleViolet = this.add.image(this.scale.width, 0, 'circle-violet').setScale(0.5);
        const plane1 = this.add.image(202, 70, 'planet-1').setScale(0.5);
        const plane2 = this.add.image(this.scale.width * 0.8, 380, 'planet-2').setScale(0.5);
        const cloud1 = this.add.image(this.scale.width * 0.8, 120, 'cloud-1').setScale(0.5);
        const cloud2 = this.add.image(303, 300, 'cloud-2').setScale(0.5);
        this.ground = this.add.image(0, this.scale.height, 'ground-terrain').setScale(0.5).setOrigin(0, 1);
        this.ground.displayWidth = this.scale.width;
        this.circleViolet.setY(this.scale.height-400);

        this.carrotTerrain = new TerrainContainer(
            this,
            0, 0,
            'carrot-terrain',
            'carrot-sprite',
            0,
            'badge-taco',
            () => {
                this.progressContainer.addPoints()
            }
        );
        this.carrotTerrain.setScale(0.5);
        this.carrotTerrain.setPointerEvent();

        cloud1.setInteractive({cursor: 'pointer'});
        cloud1.setInteractive(new Phaser.Geom.Circle(45, 46, 45), Phaser.Geom.Circle.Contains);
        this.input.enableDebug(cloud1, 0x000000);

        this.cornTerrain = new TerrainContainer(
            this,
            0, 0,
            'corn-terrain',
            'corn-sprite',
            0,
            'badge-taiko',
            () => {
                this.progressContainer.addPoints()

                this.time.delayedCall(500, () => {
                    this.titleContainer.switchLabel();
                    this.showButton();
                }, [], this);
            }
        );
        this.cornTerrain.setScale(0.5);

        this.progressContainer = new ProgressContainer(this, 100, 600,
            () => {
                this.time.delayedCall(200, () => {
                    this.cornTerrain?.setPointerEvent()
                }, [], this);
            },
            () => {

            });
        this.titleContainer = new TitleContainer(this, 0, 120).setScale(0.5);
        this.progressContainer.setScale(0.5);
        this.socialLinksContainer = new SocialLinksContainer(this, 600, 600).setScale(0.55);

        this.centeredGroup = this.add.group();
        this.centeredGroup.setOrigin(0.5);
        this.centeredGroup.add(this.titleContainer);
        this.scaleContainer = this.add.container(0, 0);
        this.scaleContainer.add(this.cornTerrain);

        this.refreshCentered();
        this.resize(this.scale.width, this.scale.height);
        console.log('created phaser');
    }

    private refreshCentered() {
        this.centeredGroup.setX(this.scale.width/2);
    }

    private showButton() {
        const xImage = this.add.image(0, 255, 'x').setScale(0.5);
        xImage.alpha = 0;
        const buttonImage = this.add.image(0, 400, 'button-be-early').setScale(0.5);
        buttonImage.alpha = 0;
        this.centeredGroup.add(xImage);
        this.centeredGroup.add(buttonImage);
        this.refreshCentered();

        this.tweens.addMultiple([{
            targets: xImage,
            alpha: 1,
            duration: 800,
        }, {
            targets: buttonImage,
            alpha: 1,
            duration: 800,
            ease: 'Bounce.easeOut',
            delay: 700,
            onComplete: () => {
                buttonImage.setInteractive({ cursor: 'pointer' });
                buttonImage.on('pointerup', () => {
                    EventBus.emit('show-email-dialog', this);
                }, this);
            }
        }]);
    }

    resize(innerWidth: number, innerHeight: number) {
        const centerX = innerWidth / 2;
        if (!this.socialLinksContainer || !this.progressContainer) {
            return;
        }
        this.centeredGroup.setX(centerX);
        const distanceFromCenter = innerWidth * 0.2;
        this.carrotTerrain.setX(centerX + distanceFromCenter * -1);
        this.cornTerrain.setX(centerX + distanceFromCenter);

        this.ground.displayWidth = this.scale.width * 1.5;
        this.ground.setY(innerHeight*1.01);

        if (innerWidth / innerHeight >= 0.9) {
            const groundHeight = innerHeight * 0.4;
            this.ground.displayHeight = innerHeight * 0.4;
            this.socialLinksContainer.setScale();

            const scaleFactor = this.ground.displayHeight / this.ground.height;

            const footerPadding = 100 * (1 - scaleFactor);
            this.socialLinksContainer.setScale(scaleFactor);
            this.socialLinksContainer.setY(innerHeight - (groundHeight) + (groundHeight / 1.5));
            this.socialLinksContainer.setX(innerWidth - (this.socialLinksContainer.getGeneratedWidth() * scaleFactor) - footerPadding);

            this.progressContainer.setScale(scaleFactor);
            this.progressContainer.setY(innerHeight - (groundHeight) + (groundHeight / 1.5));
            this.progressContainer.setX(footerPadding);

            const terrainY = innerHeight - groundHeight;
            this.carrotTerrain.setScale(scaleFactor);
            this.carrotTerrain.setY(terrainY);
            this.cornTerrain.setScale(scaleFactor);
            this.cornTerrain.setY(terrainY);
        }
    }

}

