import {EventBus} from "../../EventBus";
import {ButtonRefCode} from "./ButtonRefCode";

declare const WebFont: any;

export class RefContainer extends Phaser.GameObjects.Container {

    private readonly bgBoxRef: Phaser.GameObjects.Image;
    private readonly bgButtonGreen: Phaser.GameObjects.Image;
    private readonly refCableTop: Phaser.GameObjects.Image;
    private readonly refCableBottom: Phaser.GameObjects.Image;
    private readonly icArrow: Phaser.GameObjects.Image;
    private readonly buttonRefCode: ButtonRefCode

    private labelText: Phaser.GameObjects.Text;
    private refValueText: Phaser.GameObjects.Text;

    private readonly maxWidth = 300;
    private readonly maxWidthLabel = 400;

    private addressText = '';

    preload(): void {
    }

    constructor(scene: Phaser.Scene, x: number, y: number) {
        super(scene, x, y);

        this.refCableTop = scene.add.image(0, -60, "ref-cable-top")
        this.refCableBottom = scene.add.image(0, 50, "ref-cable-bottom")
        this.bgBoxRef = scene.add.image(0, 0, "bg-box-ref")
        this.icArrow = scene.add.image(-105, 0, "ic-boost")
        this.buttonRefCode = new ButtonRefCode(scene, 250, 0);
        this.buttonRefCode.setVisible(false);

        this.add([this.refCableTop, this.refCableBottom, this.bgBoxRef, this.icArrow])
        scene.add.existing(this);

        const textStyle: Phaser.Types.GameObjects.Text.TextStyle = {
            fontFamily: 'Jersey20',
            fontSize: 20,
            color: '#5CFF59',
            fontStyle: '500',
            resolution: 100
        };

        WebFont.load({
            custom: {
                families: ['Jersey20']
            },
            active: () => {
                this.labelText.setStyle({...textStyle, wordWrap: {width: this.maxWidthLabel}})
                this.refValueText.setStyle({...textStyle, color: '#FFFFFF', wordWrap: {width: this.maxWidth}})
            }
        });
        this.labelText = scene.add.text(-95, 0, 'Copy Ref:', {...textStyle, wordWrap: {width: this.maxWidthLabel}}).setOrigin(0, 0.5);
        this.add(this.labelText);
        if (this.labelText.width > this.maxWidthLabel) {
            this.labelText.setWordWrapWidth(this.maxWidthLabel);
        }

        this.refValueText = scene.add.text(0, 0, this.addressText, {...textStyle, color: '#FFFFFF', wordWrap: {width: this.maxWidth}}).setOrigin(0, 0.5);
        this.add(this.refValueText);

        this.setAddress(this.addressText);
        this.bgBoxRef.on('pointerup', () => {
            this.copyText()
        }, this);

        this.add(this.buttonRefCode);

        EventBus.on('address', (address: string | null) => {
            this.setAddress(address)
            this.buttonRefCode.setVisible(!!address)
        });
    }

    setAddress(address: string | null) {
        this.addressText = address ?? '';
        if (this.refValueText) {
            this.refValueText.text = address ? (address?.substring(0, 7) + '..')  : '';
        }
        if (this.refValueText.width > this.maxWidth) {
            this.refValueText.setWordWrapWidth(this.maxWidth);
        }
        if (address) {
            this.bgBoxRef.setInteractive({ cursor: 'pointer' });
        } else {
            this.bgBoxRef.disableInteractive()
        }
    }

    showCables(show: boolean) {
        this.refCableTop.setVisible(show)
        this.refCableBottom.setVisible(show)
    }

    resize(isMobile: boolean) {
        if (isMobile) {
            this.buttonRefCode.setPosition(0, 80);
        } else {
            this.buttonRefCode.setPosition(250, 0);
        }
    }

    private timerEvent: Phaser.Time.TimerEvent;
    private copyText() {
        if (!this.addressText) {
            return;
        }
        EventBus.emit('copy-address', this);
        this.refValueText.text = 'Copied!';
        this.timerEvent?.destroy()
        this.timerEvent = this.scene.time.delayedCall(1500, () => {
            this.setAddress(this.addressText)
        }, [], this);
    }

}
