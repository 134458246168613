import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {ShopService} from "../../core/services/shop.service";
import {SessionQuery} from "../../core/stores/session/session.query";
import {LoaderBrigadeComponent} from "../../shared/loader-brigade/loader-brigade.component";
import {ToastService, ToastType} from "../../core/services/toast.service";
import {BackendTacoService} from "../../core/services/backend-taco.service";
import {ShopItem} from "../../core/models/models";

@Component({
  selector: 'app-shop',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    LoaderBrigadeComponent,
    NgClass
  ],
  templateUrl: './shop.component.html',
  styleUrl: './shop.component.css'
})
export class ShopComponent implements OnInit {

  @Output() close = new EventEmitter<boolean>();
  @Output() refresh = new EventEmitter();

  isLoading = false;
  isTransacting = false;
  isVerifying = false;
  list: ShopItem[] = [];

  constructor(
      private _session: SessionQuery,
      protected shopService: ShopService,
      protected _toastService: ToastService,
      protected _backendTacoService: BackendTacoService,
  ) {
  }

  ngOnInit() {
    this.refreshShop();
  }

  async refreshShop() {
    this.isLoading = true;
    this.list = await this.shopService.getProducts();
    this.isLoading = false;
  }

  closeModal(): void {
    this.close.emit();
  }

  async clickProduct(product: any) {
    this.isVerifying = false;
    this.isTransacting = true;
    const result = await this.shopService.claimProduct(this._session.address!!, product.id, () => {
        this.isVerifying = true;
    });
    this.isTransacting = false;
    if (result.success) {
      this._toastService.open('Product claimed', ToastType.SUCCESS);
      this.refresh.emit();
      this.refreshShop();
    }
  }

  protected readonly Array = Array;
}
