import {Injectable} from "@angular/core";
import {
    readContract,
} from "@wagmi/core";
import {config} from "../utils/web3modal";
import {environment} from "../../../environments/environment";
import * as Moment from "moment";
import {SessionQuery} from "../stores/session/session.query";
import {ConnectorService} from "./connector.service";


@Injectable({providedIn: 'root'})
export class GameService {

    constructor(
        private _session: SessionQuery,
        private _connectorService: ConnectorService
    ) {}

    async checkUserShards(address: string): Promise<number> {
        const result = await readContract(config, {
            address: environment.shardContract as any,
            abi: environment.shardAbi,
            functionName: 'shards',
            args: [address]
        });
        return Number(result || 0);
    }

    async checkCapsule(): Promise<any> {
        const result = await readContract(config, {
            address: environment.capsuleContract as any,
            abi: environment.capsuleAbi,
            functionName: 'users_timestamps',
            args: [this._session.address]
        });
        const coolDown = await readContract(config, {
            address: environment.capsuleContract as any,
            abi: environment.capsuleAbi,
            functionName: 'pickCooldown'
        });
        const capsuleNumber = await readContract(config, {
            address: environment.capsuleContract as any,
            abi: environment.capsuleAbi,
            functionName: 'maxCapsuleNumber'
        });
        return {
            cooldown: Moment.unix(Number(result)).add(Number(coolDown), 's'),
            capsuleNumber: Number(capsuleNumber)
        };
    }

    async pickCapsule(index: number): Promise<any> {
        try {
            const obj = {
                address: environment.capsuleContract,
                abi: environment.capsuleAbi,
                functionName: 'pickCapsule',
                args: [index],
                confirmations: 1,
            };
            const result = await this._connectorService.signTransaction([obj]);
            if (result?.success) {
                return {success: true};
            }
        } catch (e) {
        }
        return {success: false};
    }

    async checkShards(): Promise<any> {
        const timestamp = await readContract(config, {
            address: environment.checkinContract as any,
            abi: environment.checkingAbi,
            functionName: 'users_timestamps',
            args: [this._session.address]
        });
        const cooldown = await readContract(config, {
            address: environment.checkinContract as any,
            abi: environment.checkingAbi,
            functionName: 'checkinCooldown'
        });
        const shardsNumber = await readContract(config, {
            address: environment.checkinContract as any,
            abi: environment.checkingAbi,
            functionName: 'pointsPerCheckin'
        });
        return {
            cooldown: Moment.unix(Number(timestamp)).add(Number(cooldown), 's'),
            shardsNumber: Number(shardsNumber)
        };
    }

    async claimShards(): Promise<any> {
        try {
            const obj = {
                address: environment.checkinContract,
                abi: environment.checkingAbi,
                functionName: 'checkin',
                confirmations: 1,
            };
            const result = await this._connectorService.signTransaction([obj]);
            if (result?.success) {
                return {success: true};
            }
        } catch (e) {
        }
        return {success: false};
    }

    async checkJourneyInfo(): Promise<any> {
        const userTimestamp = await readContract(config, {
            address: environment.spaceContract as any,
            abi: environment.spaceAbi,
            functionName: 'users_timestamps',
            args: [this._session.address]
        });
        const cooldown = await readContract(config, {
            address: environment.spaceContract as any,
            abi: environment.spaceAbi,
            functionName: 'startCooldown'
        });
        const maxNumber = await readContract(config, {
            address: environment.spaceContract as any,
            abi: environment.spaceAbi,
            functionName: 'maxLightYears'
        });
        return {
            cooldown: Moment.unix(Number(userTimestamp)).add(Number(cooldown), 's'),
            maxInput: Number(maxNumber)
        };
    }

    async startJourney(input: number): Promise<any> {
        try {
            const obj = {
                address: environment.spaceContract,
                abi: environment.spaceAbi,
                functionName: 'startJourney',
                args: [input],
                confirmations: 1,
            };
            const result = await this._connectorService.signTransaction([obj]);
            if (result?.success) {
                return {success: true};
            }
        } catch (e) {
        }
        return {success: false};
    }

    async loadWheelInfo(): Promise<any> {
        const userTimestamp = await readContract(config, {
            address: environment.wheelContract as any,
            abi: environment.wheelAbi,
            functionName: 'users_timestamps',
            args: [this._session.address]
        });
        const cooldown = await readContract(config, {
            address: environment.wheelContract as any,
            abi: environment.wheelAbi,
            functionName: 'spinCooldown'
        });
        const prizes: any = await readContract(config, {
            address: environment.wheelContract as any,
            abi: environment.wheelAbi,
            functionName: 'getSlices'
        });
        return {
            cooldown: Moment.unix(Number(userTimestamp)).add(Number(cooldown), 's'),
            prizes: prizes.map((p: any) => p.shards)
        };
    }

    async spinWheel(): Promise<any> {
        try {
            const obj = {
                address: environment.wheelContract,
                abi: environment.wheelAbi,
                functionName: 'spinWheel',
                confirmations: 1,
            };
            const result = await this._connectorService.signTransaction([obj]);
            if (result?.success) {
                return {success: true};
            }
        } catch (e) {
        }
        return {success: false};
    }

}
