import {Injectable} from "@angular/core";
import {
    readContract,
} from "@wagmi/core";
import {config} from "../utils/web3modal";
import {environment} from "../../../environments/environment";
import {ConnectorService} from "./connector.service";


@Injectable({providedIn: 'root'})
export class ClickerService {

    constructor(
        private _connectorService: ConnectorService
    ) {
    }

    async readPoints(address: string): Promise<number> {
        const result = await readContract(config, {
            address: environment.clickerContract as any,
            abi: environment.clickerAbi,
            functionName: 'users_points',
            args: [address]
        });
        return Number(result);
    }

    async readReferral(address: string): Promise<any> {
        const result = await readContract(config, {
            address: environment.clickerContract as any,
            abi: environment.clickerAbi,
            functionName: 'referrals',
            args: [address]
        });
        return result;
    }

    async readTimestamp(address: string): Promise<number> {
        const result = await readContract(config, {
            address: environment.clickerContract as any,
            abi: environment.clickerAbi,
            functionName: 'users_timestamps',
            args: [address]
        });
        return Number(result);
    }

    async harvest(callbackSigned: () => void): Promise<{ success: boolean }> {
        try {
            const obj = {
                abi: environment.clickerAbi,
                address: environment.clickerContract,
                functionName: 'harvest',
                args: [],
                confirmations: 1,
            };
            const result = await this._connectorService.signTransaction([obj], callbackSigned);
            if (result?.success) {
                return {success: true};
            }
        } catch (e) {
        }
        return {success: false};
    }

    public async linkReferralCode(address: string): Promise<{ success: boolean }> {
        try {
            const obj = {
                abi: environment.clickerAbi,
                address: environment.clickerContract,
                functionName: 'link',
                args: [address],
                confirmations: 1,
            };
            const result = await this._connectorService.signTransaction([obj]);
            if (result?.success) {
                return {success: true};
            }
        } catch (e) {
        }
        return {success: false};
    }

}
