import {Injectable} from "@angular/core";
import {getMessaging, getToken} from "firebase/messaging";
import {SessionStore} from "../stores/session/session.store";
import {FIREBASE_TOPIC, MESSAGING_VAPID_KEY} from "../core.constants";
import {FirebaseFunctionsService} from "./firebase-functions.service";
import {LogService} from "./log.service";
import {LocalStorageService} from "./local-storage.service";

@Injectable({providedIn: 'root'})
export class NotificationService {

    hasSent = false

    constructor(
        private _sessionStore: SessionStore,
        private _log: LogService,
        private _firebaseFunctions: FirebaseFunctionsService,
        private _localStorageService: LocalStorageService,
    ) {
    }

    requestPermission() {
        if (this.hasSent) {
            this.hasSent = true;
            return;
        }
        try {
            this._log.log('Requesting permission...');
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    const messaging = getMessaging();
                    getToken(messaging, { vapidKey: MESSAGING_VAPID_KEY }).then((currentToken) => {
                        if (currentToken) {
                            this._log.log('token messaging: ' + currentToken);
                            this._firebaseFunctions.registerToTopic(currentToken, FIREBASE_TOPIC).then(() => {
                                this._log.log('success register: ' + currentToken);
                            }, (e) => {
                                this._log.error('error while register', e);
                            })
                        } else {
                            this._log.log('No registration token available. Request permission to generate one.');
                        }
                    }).catch((err) => {
                        this._log.error('An error occurred while retrieving token. ', err);
                    });
                }
            });
        } catch (e) {
            this._log.error(e)
        }
    }
}
